import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { useNavigate } from 'react-router-dom';
import { ButtonContainer, } from './styles';

import Button from '../../../Components/Button/ButtonMaterial';
import Sidebar from '../../../Components/SideBar/MiniDrawer';

function JavascriptHome() {
    const navigate = useNavigate();


    function chamaOqueJavascript() {
        navigate('/oqueejavascript');
    }
    function chamJsAdicionarPagina() {
        navigate('/jsadicionarpagina');
    }
    function chamaJsVariaveis() {
        navigate('/jsvariaveis');
    }
    function chamaJsComparacao() {
        navigate('/jscomparacao');
    }
    function chamaJsFuncaoSegundoGrau() {
        navigate('/jsfuncaosegundograu');
    }
    function chamaJsOperacoesMatematicas() {
        navigate('/jsoperacoesmatematicas');
    }
    function chamaJsFuncoesMath() {
        navigate('/jsfuncoesmath');
    }
    function chamaJsDom() {
        navigate('/jsdom');
    }
    function chamaJsFormularioDom() {
        navigate('/jsformulariodom');
    }
    function chamaClasseDom() {
        navigate('/jsclassedom');
    }
    function chamaSessionLocalStorage() {
        navigate('/jssessionlocalstorage');
    }
    function chamaVetores() {
        navigate('/jsvetores');
    }

    return (
        <div>
            <Sidebar>
                <h1>Seção Javascript</h1>

                <ButtonContainer>
                    {/* <Button texto='oi' /> */}
                    <Button texto='O que é Javascript' onClick={chamaOqueJavascript} setMarginRight='5px' setMarginBottom='5px' />
                    <Button texto='Adicionar no HTML' onClick={chamJsAdicionarPagina} setMarginRight='5px' setMarginBottom='5px' />
                    <Button texto='Variáveis' onClick={chamaJsVariaveis} setMarginRight='5px' setMarginBottom='5px' />
                    <Button texto='Operadores de comparação' onClick={chamaJsComparacao} setMarginRight='5px' setMarginBottom='5px' />
                    <Button texto='Função de 2º grau' onClick={chamaJsFuncaoSegundoGrau} setMarginRight='5px' setMarginBottom='5px' />
                    <Button texto='Operações Matemáticas' onClick={chamaJsOperacoesMatematicas} setMarginRight='5px' setMarginBottom='5px' />
                    <Button texto='Funções Math' onClick={chamaJsFuncoesMath} setMarginRight='5px' setMarginBottom='5px' />
                    <Button texto='DOM' onClick={chamaJsDom} setMarginRight='5px' setMarginBottom='5px' />
                    <Button texto='Formulário Dom' onClick={chamaJsFormularioDom} setMarginRight='5px' setMarginBottom='5px' />
                    <Button texto='Classe Dom' onClick={chamaClasseDom} setMarginRight='5px' setMarginBottom='5px' />
                    <Button texto='Local e Session Storage' onClick={chamaSessionLocalStorage} setMarginRight='5px' setMarginBottom='5px' />

                    <Button texto='Vetores' onClick={chamaVetores} setMarginRight='5px' setMarginBottom='5px' />




                </ButtonContainer>
            </Sidebar>
        </div>
    );
}

export default JavascriptHome;


