import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { useNavigate } from 'react-router-dom';


import Button from '../../../Components/Button/ButtonMaterial';
import Sidebar from '../../../Components/SideBar/MiniDrawer';

function OqueeSql() {
    const navigate = useNavigate();


    return (
        <div>
            <Sidebar>
                <h1>SQL (Structured Query Language)</h1>

                <div>
                    SQL é uma linguagem padronizada usada para gerenciar e manipular dados em bancos de dados relacionais. Aqui estão alguns pontos-chave:
                </div>
                <div>
                    <ul>
                        <li><p><b>Linguagem Declarativa:</b> SQL é uma linguagem declarativa, o que significa que os usuários especificam o que desejam realizar,
                            em vez de como fazê-lo. Eles descrevem a lógica da consulta, mas não necessariamente os passos exatos para executá-la.</p></li>

                        <li><p><b> Manipulação de Dados:</b> SQL pode ser usado para realizar diversas operações em um banco de dados, incluindo inserção, atualização, exclusão e seleção de dados.</p></li>

                        <li><p><b> Padrão ANSI:</b> SQL é baseado em um padrão estabelecido pela ANSI (American National Standards Institute).
                            Embora existam variações e extensões em diferentes implementações de SGBDs, a maioria dos comandos SQL segue esse padrão.</p></li>

                        <li><p><b>Linguagem de Conjunto:</b> SQL é projetado para operar em conjuntos de dados. Isso significa que pode processar múltiplos registros ao mesmo tempo, em vez de apenas um registro por vez.
                        </p></li>

                        <li><p><b>Ampla Aplicação:</b> SQL é amplamente utilizado em uma variedade de contextos, desde bancos de dados empresariais até aplicativos da web e móveis.</p></li>
                    </ul>
                </div>
            </Sidebar>
        </div>
    );
}

export default OqueeSql;


