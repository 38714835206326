import styled from "styled-components";
// <div >
//   <h1 className="text-3xl font-bold underline">
//     Hello Home Page
//   </h1>
// </div>

export const ContainerStyled = styled.div`
    background-color: #f0e9e9;
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;
