import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { useNavigate } from 'react-router-dom';


import Button from '../../../Components/Button/ButtonMaterial';
import Sidebar from '../../../Components/SideBar/MiniDrawer';

function SqlCrud() {
    const navigate = useNavigate();

    const select = `
-- Seleciona todos os registros da tabela
SELECT * FROM nome_da_tabela;

-- Seleciona registros específicos com uma condição
SELECT coluna1, coluna2 FROM nome_da_tabela WHERE condição;

-- Seleciona registros com uma correspondência parcial
SELECT * FROM nome_da_tabela WHERE coluna LIKE 'texto%';

-- Seleciona registros com ordenação
SELECT * FROM nome_da_tabela ORDER BY coluna ASC|DESC;
    `;

    const update = `
    -- Atualiza um único registro
    UPDATE nome_da_tabela SET coluna1 = valor1, coluna2 = valor2 WHERE condição;
    
    -- Atualiza vários registros
    UPDATE nome_da_tabela SET coluna = valor WHERE condição;
    `;

    const deleteSql = `
    -- Exclui registros com uma condição
    DELETE FROM nome_da_tabela WHERE condição;
    
    -- Exclui todos os registros da tabela
    DELETE FROM nome_da_tabela;
    `;


    const insert = `
    -- Insere um único registro
INSERT INTO nome_da_tabela (coluna1, coluna2) VALUES (valor1, valor2);

-- Insere múltiplos registros
INSERT INTO nome_da_tabela (coluna1, coluna2) VALUES (valor1, valor2), (valor3, valor4), ...;
    `;




    return (
        <div>
            <Sidebar>
                <h1>CRUD:</h1>

                <div>
                    CRUD é um acrônimo que representa as quatro operações básicas de manipulação de dados em sistemas de banco de dados relacionais ou em qualquer tipo de sistema de gerenciamento de informações.
                    As quatro operações são: Create (Criar), Read (Ler), Update (Atualizar) e Delete (Excluir).
                </div>
                <div>
                    <ul>
                        <li><p><b>Create (Criar): </b>Refere-se à operação de criar novos registros ou entradas no banco de dados. Isso geralmente é feito por meio do comando SQL INSERT em bancos de dados relacionais.</p></li>

                        <li><p><b>Read (Ler): </b>Refere-se à operação de recuperar ou ler os dados existentes do banco de dados. Isso é feito por meio do comando SQL SELECT, que permite a consulta de registros com base em diferentes critérios.</p></li>

                        <li><p><b>Update (Atualizar): </b>Refere-se à operação de modificar os dados existentes no banco de dados. Isso geralmente é feito por meio do comando SQL UPDATE, que permite a alteração de valores em um ou mais registros.</p></li>

                        <li><p><b>Delete (Excluir): </b>Refere-se à operação de remover registros ou entradas do banco de dados.
                            Isso é feito por meio do comando SQL DELETE, que exclui registros com base em determinados critérios.
                        </p></li>

                        <li><p><b>Comunidade Ativa:</b>  Como uma das tecnologias de banco de dados mais populares, o MySQL possui uma grande comunidade de usuários e desenvolvedores. Isso significa que há uma abundância de recursos, documentação e suporte disponíveis.</p></li>
                    </ul>
                </div>
                <div>
                    Juntas, essas operações formam o CRUD, que é um conjunto de funcionalidades essenciais para qualquer aplicação que envolva a manipulação de dados.
                    Um sistema que suporta essas quatro operações é considerado capaz de realizar todas as operações básicas de gerenciamento de dados.
                    O CRUD é comumente usado em desenvolvimento de software para descrever as operações de um sistema ou aplicativo que envolvem a interação com um banco de dados ou
                    qualquer outra fonte de armazenamento de dados.
                </div>
                <div>
                    <h1>Exemplos</h1>
                    <h3>SELECT (Selecionar)</h3>
                    <SyntaxHighlighter language="sql" style={darcula}>
                        {select}
                    </SyntaxHighlighter>

                    <h3>UPDATE (Atualizar)</h3>
                    <SyntaxHighlighter language="sql" style={darcula}>
                        {update}
                    </SyntaxHighlighter>

                    <h3>DELETE (Excluir)</h3>
                    <SyntaxHighlighter language="sql" style={darcula}>
                        {deleteSql}
                    </SyntaxHighlighter>

                    <h3>INSERT (Inserir)</h3>
                    <SyntaxHighlighter language="sql" style={darcula}>
                        {insert}
                    </SyntaxHighlighter>
                </div>

                <div>
                    Lembre-se de substituir nome_da_tabela, coluna, valor e condição pelos nomes e valores reais que você está usando em seu banco de dados. Esses são apenas exemplos básicos,
                    e é importante entender como esses comandos funcionam para aplicá-los corretamente em seu próprio contexto.
                </div>
            </Sidebar>
        </div>
    );
}

export default SqlCrud;


