import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { useNavigate } from 'react-router-dom';


import Button from '../../Components/Button/ButtonMaterial';
import Sidebar from '../../Components/SideBar/MiniDrawer';

function ProgramacaoOrientadaObjetos() {
    const navigate = useNavigate();
    const definicaoClasse = `
    // Definição da classe 'Pessoa'
    class Pessoa {
      // Método construtor para inicializar uma pessoa com nome e idade
      constructor(nome, idade) {
        this.nome = nome;
        this.idade = idade;
      }
    
      // Método para exibir informações da pessoa
      exibirInfo() {
        console.log('Nome: ' + this.nome+', Idade:'+  this.idade });
      }
    }`;

    const objetoJavascript = `// Criando um objeto 'usuario' a partir da classe 'Pessoa'
    let usuario = new Pessoa("João", 30);
    
    // Acessando propriedades e métodos do objeto
    usuario.exibirInfo();`;

    const Encapsulamento = `// Exemplo de encapsulamento
    class ContaBancaria {
      constructor(saldoInicial) {
        let saldo = saldoInicial; // Propriedade privada
    
        // Método para acessar o saldo
        this.getSaldo = function() {
          return saldo;
        };
    
        // Método para modificar o saldo
        this.depositar = function(valor) {
          saldo += valor;
        };
    
        // Método para modificar o saldo
        this.sacar = function(valor) {
          saldo -= valor;
        };
      }
    }
    
    let conta = new ContaBancaria(100); // Criação de um objeto 'conta'
    console.log(conta.getSaldo()); // Exibe o saldo inicial (encapsulado)
    conta.depositar(50); // Deposita 50 na conta
    console.log(conta.getSaldo()); // Exibe o novo saldo`;

    const Abstracao = `// Exemplo de abstração
    class Animal {
      constructor(nome) {
        this.nome = nome;
      }
    
      emitirSom() {
        // Método abstrato
        throw new Error('Método emitirSom() deve ser implementado');
      }
    }
    
    class Cachorro extends Animal {
      emitirSom() {
        console.log('Au Au!');
      }
    }
    
    let cachorro = new Cachorro('Rex');
    cachorro.emitirSom(); // Exibe 'Au Au!'`;

    const Heranca = `// Exemplo de herança
    class Veiculo {
      constructor(marca) {
        this.marca = marca;
      }
    
      acelerar() {
        console.log('Veículo acelerando...');
      }
    }
    
    class Carro extends Veiculo {
      constructor(marca, modelo) {
        super(marca);
        this.modelo = modelo;
      }
    
      frear() {
        console.log('Carro freando...');
      }
    }
    
    let carro = new Carro('Toyota', 'Corolla');
    carro.acelerar(); // Método herdado de Veiculo
    carro.frear(); // Método próprio de Carro`;

    const Polimorfismo = `// Exemplo de polimorfismo
    class Forma {
      calcularArea() {
        throw new Error('Método calcularArea() deve ser implementado');
      }
    }
    
    class Retangulo extends Forma {
      constructor(base, altura) {
        super();
        this.base = base;
        this.altura = altura;
      }

      calcularArea() {
        return this.base * this.altura;
      }
    }
    
    class Circulo extends Forma {
      constructor(raio) {
        super();
        this.raio = raio;
      }
    
      calcularArea() {
        return Math.PI * this.raio ** 2;
      }
    }
    
    let retangulo = new Retangulo(5, 10);
    console.log('Área do retângulo:', retangulo.calcularArea());
    
    let circulo = new Circulo(5);
    console.log('Área do círculo:', circulo.calcularArea());`;

    const construtoreseDestrutores = `// Definição da classe Pessoa
    class Pessoa {
      // Método construtor para inicializar uma pessoa com nome e idade
      constructor(nome, idade) {
        this.nome = nome; // Propriedade nome
        this.idade = idade; // Propriedade idade
      }
    
      // Método para cumprimentar a pessoa
      cumprimentar() {
        console.log('Olá, meu nome é '+ this.nome + 'e tenho ' + this.idade + ' anos.');
      }
    
      // Método destrutor (em JavaScript não há destrutores, mas podemos simular liberando referências)
      // Este método é chamado quando um objeto é "destruído"
      // Aqui apenas imprimimos uma mensagem
      destruir() {
        console.log(this.nome +' foi destruído.');
      }
    }
    
    // Criando um objeto 'pessoa1' a partir da classe 'Pessoa' usando o construtor
    let pessoa1 = new Pessoa("João", 30);
    
    // Chamando o método cumprimentar() do objeto pessoa1
    pessoa1.cumprimentar();
    
    // Acessando propriedades do objeto pessoa1
    console.log('Nome: ' +pessoa1.nome);
    console.log('Idade: ' + pessoa1.idade);
    
    // Simulando a "destruição" do objeto pessoa1 (liberando referências)
    pessoa1 = null; // A referência para o objeto é removida, então, em JavaScript, o garbage collector irá lidar com a "destruição" do objeto`;
    return (
        <div>
            <Sidebar>
                <h1>O que é Programação Orientada a Objetos (POO)?</h1>

                <p>
                    A Programação Orientada a Objetos (POO) é um paradigma de programação que se baseia no conceito de "objetos",
                    que podem conter dados (também conhecidos como atributos ou propriedades) e código (métodos ou funções).
                    Em POO, o foco principal é modelar o mundo real em termos de objetos e suas interações.
                </p>

                <h3>O que é uma Classe?</h3>
                <p>
                    Uma classe é uma estrutura de programação que serve como um modelo para a criação de objetos. Ela define o comportamento e as características dos objetos
                    que serão criados a partir dela. Uma classe contém propriedades para representar dados e métodos para representar comportamentos.
                </p>
                <h3>Conceitos Chave da Programação Orientada a Objetos:</h3>
                <ul>
                    <li><b>Encapsulamento:</b> É o conceito de agrupar dados (propriedades) e comportamentos (métodos) relacionados em um único objeto.
                        Isso permite controlar o acesso aos dados e proteger sua integridade.
                    </li>

                    <li><b>Abstração:</b> É o processo de simplificar complexidades, abstraindo detalhes desnecessários e destacando apenas as características essenciais de um objeto.
                        Isso ajuda a modelar o mundo real de forma mais eficaz.
                    </li>

                    <li><b>Herança:</b> É um mecanismo que permite que uma classe herde propriedades e métodos de outra classe, promovendo a reutilização de
                        código e a criação de hierarquias de classes.
                    </li>

                    <li><b>Polimorfismo</b> É a capacidade de objetos de diferentes classes serem tratados de forma uniforme, permitindo que métodos com o mesmo nome se comportem
                        de maneiras diferentes em classes diferentes.
                    </li>

                    <li><b>Classes e Objetos:</b> Classes são os moldes usados para criar objetos. Os objetos são instâncias de classes, ou seja, são as entidades
                        reais que possuem propriedades específicas e podem realizar ações definidas pelos métodos da classe.
                    </li>

                    <li><b>Métodos e Propriedades:</b> Métodos representam os comportamentos de um objeto e são responsáveis por realizar ações.
                        Propriedades representam os dados associados a um objeto e descrevem suas características.
                    </li>

                    <li><b>Construtores e Destrutores:</b> Construtores são métodos especiais chamados automaticamente quando um objeto é criado. Eles são usados para
                        inicializar o estado inicial de um objeto. Destrutores são métodos chamados quando um objeto é destruído, e são usados para realizar limpezas finais.
                    </li>
                </ul>
                <p>Em resumo, a Programação Orientada a Objetos é um paradigma que organiza o código em torno de objetos, que são entidades que possuem características (propriedades)
                    e comportamentos (métodos). As classes são os modelos usados para criar esses objetos, e os conceitos de encapsulamento, abstração, herança, polimorfismo e outros são
                    fundamentais para construir sistemas eficientes e modularizados.
                </p>

                <h1 style={{ color: 'red' }}>Mas antes de ver exemplos, devo avisar que</h1>
                <p>Cada linguagem de programação tem sua própria maneira de tratar objetos e implementar conceitos de programação orientada a objetos (POO).
                    Embora os conceitos fundamentais, como classes, objetos, métodos e propriedades, sejam comuns em muitas linguagens, as especificidades da implementação podem variar
                    significativamente de uma linguagem para outra.
                </p>
                <p>Ou seja, embora os conceitos de orientação a objetos sejam universais, a implementação e a sintaxe para trabalhar com objetos podem variar de acordo com
                    a linguagem de programação utilizada.
                </p>
                <h1>Alguns exemplos em Javascript</h1>
                <h3>Classe em JavaScript:</h3>
                <SyntaxHighlighter language="javascript" style={darcula}>
                    {definicaoClasse}
                </SyntaxHighlighter>

                <h3>Objeto em JavaScript:</h3>
                <SyntaxHighlighter language="javascript" style={darcula}>
                    {objetoJavascript}
                </SyntaxHighlighter>

                <h3>Encapsulamento:</h3>
                <SyntaxHighlighter language="javascript" style={darcula}>
                    {Encapsulamento}
                </SyntaxHighlighter>

                <h3>Abstração:</h3>
                <SyntaxHighlighter language="javascript" style={darcula}>
                    {Abstracao}
                </SyntaxHighlighter>

                <h3>Herança:</h3>
                <SyntaxHighlighter language="javascript" style={darcula}>
                    {Heranca}
                </SyntaxHighlighter>

                <h3>Polimorfismo:</h3>
                <SyntaxHighlighter language="javascript" style={darcula}>
                    {Polimorfismo}
                </SyntaxHighlighter>

                <h3>Classes e objetos, métodos e propriedades, construtores e destrutores</h3>
                <SyntaxHighlighter language="javascript" style={darcula}>
                    {construtoreseDestrutores}
                </SyntaxHighlighter>
                <p>
                    Embora JavaScript não tenha destrutores como outras linguagens (como C++), a remoção de todas as referências para um objeto faz com que o garbage
                    collector do JavaScript libere a memória associada ao objeto, o que é uma forma de "destruição" de objetos em JavaScript.
                </p>
            </Sidebar>
        </div >
    );
}

export default ProgramacaoOrientadaObjetos;


