import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { useNavigate } from 'react-router-dom';


import Button from '../../../Components/Button/ButtonMaterial';
import Sidebar from '../../../Components/SideBar/MiniDrawer';

function ColegioPlanoSistemasOperacionais() {
    const navigate = useNavigate();

    const handleDownloadArquiteturaSistemas = () => {
        const pdfUrl = './aulaSO.pdf';  // Substitua pelo caminho correto do seu PDF
        const link = document.createElement('a');
        link.href = pdfUrl;
        link.download = 'aulaSO.pdf';
        link.click();
    };

    return (
        <div>
            <Sidebar>
                <h1>Sistemas Operacionais</h1>

                <h3>O que é um sistema operacional</h3>
                <p>Imagine que você tem um smartphone ou um computador. Ele tem várias
                    partes, como o processador (o cérebro do dispositivo), a memória (onde ele guarda
                    as informações), e dispositivos de entrada e saída (como teclado, mouse, tela). Para
                    fazer tudo funcionar junto de forma organizada, você precisa de alguém que
                    “comande” tudo isso. Esse “alguém” é o sistema operacional.
                </p>

                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

                    <h1>PDF</h1>
                    <Button texto='Baixar PDF' onClick={handleDownloadArquiteturaSistemas} setMarginRight='5px' />
                </div>

            </Sidebar>
        </div>
    );
}

export default ColegioPlanoSistemasOperacionais;


