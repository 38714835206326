import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { useNavigate } from 'react-router-dom';


import Button from '../../../Components/Button/ButtonMaterial';
import Sidebar from '../../../Components/SideBar/MiniDrawer';

function JsFuncaoSegundoGrau() {

    const navigate = useNavigate();

    const funcaoSegundoGrau = `
    function calcularEquacaoSegundoGrau(a, b, c) {
        // Calcula o delta
        var delta = b * b - 4 * a * c;
    
        // Verifica se a equação tem raízes reais
        if (delta < 0) {
            return "A equação não possui raízes reais.";
        } else if (delta === 0) {
            // Se o delta for zero, há apenas uma raiz real
            var raiz = -b / (2 * a);
            return "A equação possui uma raiz real: " + raiz;
        } else {
            // Se o delta for positivo, há duas raízes reais
            var raiz1 = (-b + Math.sqrt(delta)) / (2 * a);
            var raiz2 = (-b - Math.sqrt(delta)) / (2 * a);
            return "As raízes reais da equação são: " + raiz1 + " e " + raiz2;
        }
    }
    
    // Exemplo de uso
    var a = 1;
    var b = -3;
    var c = 2;
    console.log(calcularEquacaoSegundoGrau(a, b, c));`;



    return (
        <div>
            <Sidebar>
                <h1>Função de 2º grau</h1>

                <p>A equação de segundo grau, ou equação quadrática, é uma ferramenta matemática fundamental que descreve uma variedade
                    de fenômenos em várias áreas, como física, engenharia, economia, entre outras.
                </p>


                <SyntaxHighlighter language="javascript" style={darcula}>
                    {funcaoSegundoGrau}
                </SyntaxHighlighter>

            </Sidebar>
        </div>
    );
}

export default JsFuncaoSegundoGrau;


