import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { useNavigate } from 'react-router-dom';


import Button from '../../../Components/Button/ButtonMaterial';
import Sidebar from '../../../Components/SideBar/MiniDrawer';

function HtmlLinksNavegacao() {
    const navigate = useNavigate();

    const codigo = `
    <!DOCTYPE html>
    <html lang="en">
    <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Navegação com Links</title>
        <style>
            /* Estilos para a barra de navegação */
            nav {
                background-color: #333;
                overflow: hidden;
            }
    
            /* Estilos para os links na barra de navegação */
            nav a {
                float: left;
                display: block;
                color: white;
                text-align: center;
                padding: 14px 20px;
                text-decoration: none;
            }
    
            /* Estilos para os links na barra de navegação quando passa o mouse */
            nav a:hover {
                background-color: #ddd;
                color: black;
            }
        </style>
    </head>
    <body>
    
    <!-- Barra de navegação -->
    <nav>
        <a href="#home">Home</a>
        <a href="#about">Sobre</a>
        <a href="#services">Serviços</a>
        <a href="#contact">Contato</a>
    </nav>
    
    <!-- Conteúdo da página -->
    <div style="padding:20px">
        <h2 id="home">Home</h2>
        <p>Bem-vindo à nossa página inicial.</p>
        
        <h2 id="about">Sobre</h2>
        <p>Saiba mais sobre nós.</p>
        
        <h2 id="services">Serviços</h2>
        <p>Conheça nossos serviços.</p>
        
        <h2 id="contact">Contato</h2>
        <p>Entre em contato conosco.</p>
    </div>
    
    </body>
    </html>
  `;


    return (
        <div>
            <Sidebar>
                <h1>Links e navegação</h1>

                <p>
                    Neste exemplo, temos uma barra de navegação simples com quatro links: "Home", "Sobre", "Serviços" e "Contato". Quando você clica em um link, ele leva você à seção correspondente na página.
                    Os estilos CSS são usados para estilizar os links na barra de navegação e para fornecer uma aparência visual quando o mouse passa sobre eles.
                </p>

                <SyntaxHighlighter language="html" style={darcula}>
                    {codigo}
                </SyntaxHighlighter>


            </Sidebar>
        </div>
    );
}

export default HtmlLinksNavegacao;


