import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { useNavigate } from 'react-router-dom';


import Button from '../../../Components/Button/ButtonMaterial';
import Sidebar from '../../../Components/SideBar/MiniDrawer';

function PhpManipulandoArrays() {
    const navigate = useNavigate();

    const is_array = `<?php
    $var = array(1, 2, 3);
    if (is_array($var)) {
        echo "É um array!";
    } else {
        echo "Não é um array!";
    }
    ?>`;
    const array_keys = `<?php
    $frutas = array("maçã" => "vermelha", "banana" => "amarela", "laranja" => "laranja");
    $chaves = array_keys($frutas);
    print_r($chaves);
    ?>`;
    const sort = `<?php
    $numbers = array(4, 2, 8, 6);
    sort($numbers);
    print_r($numbers);
    ?>`;
    const asort = `<?php
    $frutas = array("maçã" => "vermelha", "banana" => "amarela", "laranja" => "laranja");
    asort($frutas);
    print_r($frutas);
    ?>`;
    const count = `<?php
    $frutas = array("maçã", "banana", "laranja");
    echo "O array possui " . count($frutas) . " elementos.";
    ?>`;
    const array_merge = `<?php
    $array1 = array("a", "b", "c");
    $array2 = array(1, 2, 3);
    $resultado = array_merge($array1, $array2);
    print_r($resultado);
    ?>`;
    const explode = `<?php
    $string = "maçã,banana,laranja";
    $array = explode(",", $string);
    print_r($array);
    ?>`;
    const implode = `<?php
    $array = array("maçã", "banana", "laranja");
    $string = implode(", ", $array);
    echo $string;
    ?>`;


    return (
        <div>
            <Sidebar>
                <h1>Manipulando Arrays em PHP</h1>
                <p>Vamos mostrar agora como manipular arrays usando as funções: is_array, array_keys, sort, asort, count, array_merge, explode e implode</p>

                <h3>is_array:</h3>
                <p>Verifica se uma variável é um array.</p>
                <SyntaxHighlighter language="php" style={darcula}>
                    {is_array}
                </SyntaxHighlighter>

                <h3>array_keys:</h3>
                <p>Retorna todas as chaves ou uma subconjunto das chaves de um array.</p>
                <SyntaxHighlighter language="php" style={darcula}>
                    {array_keys}
                </SyntaxHighlighter>

                <h3>sort:</h3>
                <p>Ordena um array em ordem crescente.</p>
                <SyntaxHighlighter language="php" style={darcula}>
                    {sort}
                </SyntaxHighlighter>

                <h3>asort:</h3>
                <p>Ordena um array associativo em ordem crescente, mantendo a associação entre chaves e valores.</p>
                <SyntaxHighlighter language="php" style={darcula}>
                    {asort}
                </SyntaxHighlighter>

                <h3>count:</h3>
                <p>Retorna o número de elementos em um array.</p>
                <SyntaxHighlighter language="php" style={darcula}>
                    {count}
                </SyntaxHighlighter>

                <h3>array_merge:</h3>
                <p>Combina os elementos de um ou mais arrays em um único array.</p>
                <SyntaxHighlighter language="php" style={darcula}>
                    {array_merge}
                </SyntaxHighlighter>

                <h3>explode:</h3>
                <p>Divide uma string em substrings com base em um delimitador e retorna um array.</p>
                <SyntaxHighlighter language="php" style={darcula}>
                    {explode}
                </SyntaxHighlighter>

                <h3>implode:</h3>
                <p>Junta os elementos de um array em uma string, usando um separador especificado.</p>
                <SyntaxHighlighter language="php" style={darcula}>
                    {implode}
                </SyntaxHighlighter>
            </Sidebar>
        </div>
    );
}

export default PhpManipulandoArrays;


