import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { useNavigate } from 'react-router-dom';


import Button from '../../../Components/Button/ButtonMaterial';
import Sidebar from '../../../Components/SideBar/MiniDrawer';

function JsformularioDom() {

    const navigate = useNavigate();

    const html = `
    <form id="meuFormulario">
    <label for="email">Email:</label>
    <input type="email" id="email" name="email">

    <label for="senha">Senha:</label>
    <input type="password" id="senha" name="senha">

    <button type="button" onclick="enviarFormulario()">Enviar</button>
    </form>
    `;

    const javascript = `
    function enviarFormulario() {
        // Obtém o valor do campo de email
        var email = document.getElementById('email').value;
    
        // Obtém o valor do campo de senha
        var senha = document.getElementById('senha').value;
    
        // Exibe os valores obtidos (este é apenas um exemplo, você pode fazer o que quiser com esses valores)
        console.log("Email:", email);
        console.log("Senha:", senha);
    }
    `;

    return (
        <div>
            <Sidebar>
                <h1> Formulário e DOM </h1>

                <p>Neste exemplo, a função <b>enviarFormulario()</b> é chamada quando o botão "Enviar" é clicado. Dentro desta função, <b>document.getElementById('email').value</b> é usado
                    para obter o valor do campo de email com o ID "email", e <b>document.getElementById('senha').value</b> é usado para obter o valor do campo de senha com o ID "senha".
                </p>

                <SyntaxHighlighter language="html" style={darcula}>
                    {html}
                </SyntaxHighlighter>
                <br />

                <p>Agora, vamos criar uma função JavaScript chamada <b>enviarFormulario()</b> para capturar os valores do email e da senha quando o botão "Enviar" for clicado:</p>

                <SyntaxHighlighter language="javascript" style={darcula}>
                    {javascript}
                </SyntaxHighlighter>
            </Sidebar>
        </div>
    );
}

export default JsformularioDom;



