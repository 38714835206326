import React from 'react';
import { ContainerStyled,ButtonStyled } from './styles';

class FileDownloadButton extends React.Component {
  downloadFile = () => {
    // URL do arquivo que você quer baixar
    const fileUrl = 'https://mateusnobre.com/scriptDesktop.bat';

    // Criar um elemento de link temporário
    const link = document.createElement('a');
    link.href = fileUrl;

    // Definir o atributo 'download' para que o navegador faça o download ao invés de navegar para o URL
    link.download = 'arquivo.zip';

    // Adicionar o link ao corpo do documento
    document.body.appendChild(link);

    // Simular o clique no link para iniciar o download
    link.click();

    // Limpar o elemento de link depois do download
    document.body.removeChild(link);
  }

  render() {
    return (
      <ContainerStyled>
        <ButtonStyled onClick={this.downloadFile}>Baixar Arquivo</ButtonStyled>
      </ContainerStyled>

    );
  }
}

export default FileDownloadButton;