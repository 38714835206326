import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { useNavigate } from 'react-router-dom';
import { ButtonContainer, } from './styles';

import Button from '../../../Components/Button/ButtonMaterial';
import Sidebar from '../../../Components/SideBar/MiniDrawer';

function HtmlHome() {
    const navigate = useNavigate();


    function chamaOqueeHtml() {
        navigate('/oqueehtml');
    }
    function chamaOqueeCss() {
        navigate('/oqueecss');
    }
    function chamaBasicoHtml() {
        navigate('/basicohtml');
    }
    function chamaHtmleCss() {
        navigate('/htmlecss');
    }
    function chamaHtmleJavascript() {
        navigate('/htmlejavascript');
    }
    function chamaHtmlTags() {
        navigate('/htmltags');
    }
    function chamaHtmlBotoes() {
        navigate('/htmlbotoes');
    }
    function chamaHtmlDisplayFlex() {
        navigate('/htmldisplayflex');
    }
    function chamaHtmlTelaLogin() {
        navigate('/htmltelalogin');
    }
    function chamaHtmlTelaCadastro() {
        navigate('/htmltelacadastro');
    }
    function chamaHtmlTabela() {
        navigate('/htmltabelas');
    }
    function chamaHtmlLinksNavegacao() {
        navigate('/htmllinksnavegacao');
    }
    function chamaImagensMultimidia() {
        navigate('/htmlimagensmultimidia');
    }
    function chamaFormulariosEntradaDados() {
        navigate('/htmlformulariosentradadados');
    }
    function chamaSeletoresAtributos() {
        navigate('/htmlseletoresatributos');
    }
    function chamaExemploSite() {
        navigate('/htmlexemplosite');
    }

    function chamabotao() {
        console.log('chamabotao');
    }


    return (
        <div>
            <Sidebar>
                <h1>Seção HTML, aqui estão os códigos básicos em html</h1>

                <ButtonContainer>
                    {/* <Button texto='oi' /> */}
                    <Button texto='O que é HTML' onClick={chamaOqueeHtml} setMarginRight='5px' setMarginBottom='5px' />
                    <Button texto='O que é CSS' onClick={chamaOqueeCss} setMarginRight='5px' setMarginBottom='5px' />
                    <Button texto='Básico HTML' onClick={chamaBasicoHtml} setMarginRight='5px' setMarginBottom='5px' />
                    <Button texto='HTML e CSS' onClick={chamaHtmleCss} setMarginRight='5px' setMarginBottom='5px' />
                    <Button texto='HTML e Javascript' onClick={chamaHtmleJavascript} setMarginRight='5px' setMarginBottom='5px' />
                    <Button texto='Tags' onClick={chamaHtmlTags} setMarginRight='5px' setMarginBottom='5px' />
                    <Button texto='Botões' onClick={chamaHtmlBotoes} setMarginRight='5px' setMarginBottom='5px' />
                    <Button texto='Display Flex' onClick={chamaHtmlDisplayFlex} setMarginRight='5px' setMarginBottom='5px' />
                    <Button texto='Tela de Login' onClick={chamaHtmlTelaLogin} setMarginRight='5px' setMarginBottom='5px' />
                    <Button texto='Tela de Cadastro' onClick={chamaHtmlTelaCadastro} setMarginRight='5px' setMarginBottom='5px' />
                    <Button texto='Tabelas' onClick={chamaHtmlTabela} setMarginRight='5px' setMarginBottom='5px' />
                    <Button texto='Links e Navegação' onClick={chamaHtmlLinksNavegacao} setMarginRight='5px' setMarginBottom='5px' />
                    <Button texto='Imagens e Multimídia' onClick={chamaImagensMultimidia} setMarginRight='5px' setMarginBottom='5px' />
                    <Button texto='Formulários e Entrada de Dados' onClick={chamaFormulariosEntradaDados} setMarginRight='5px' setMarginBottom='5px' />
                    <Button texto='Seletores e Atributos' onClick={chamaSeletoresAtributos} setMarginRight='5px' setMarginBottom='5px' />
                    <Button texto='Exemplo de site' onClick={chamaExemploSite} setMarginRight='5px' setMarginBottom='5px' />



                </ButtonContainer>
            </Sidebar>
        </div>
    );
}

export default HtmlHome;


