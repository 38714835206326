export const objJsonGratidao = {
    "alunos": [
        {
            "nome": "mateus_nobre_silva_almeidda",
            "senha": "senha123",
            "disciplinas": [
                { "materia": "Banco de dados", "tipo": "Prova", "nota": 10 },
                { "materia": "Banco de dados", "tipo": "Trabalho", "nota": 10 },
                { "materia": "Banco de dados", "tipo": "Participção", "nota": 10 },

                { "materia": "Práticas Profissionais e Empreendedoras", "tipo": "Prova", "nota": 8 },
                { "materia": "Práticas Profissionais e Empreendedoras", "tipo": "Trabalho", "nota": 10 },
                { "materia": "Práticas Profissionais e Empreendedoras", "tipo": "Participção", "nota": 10 },

                { "materia": "Desenvolvimento de Aplicativos", "tipo": "Prova", "nota": 7 },
                { "materia": "Desenvolvimento de Aplicativos", "tipo": "Trabalho", "nota": 7 },
                { "materia": "Desenvolvimento de Aplicativos", "tipo": "Participção", "nota": 7 }
            ]
        },
        {
            "nome": "nobre_astoras_almedida",
            "senha": "senha123",
            "disciplinas": [
                { "materia": "Banco de dados", "tipo": "Prova", "nota": 10 },
                { "materia": "Banco de dados", "tipo": "Trabalho", "nota": 10 },
                { "materia": "Banco de dados", "tipo": "Participção", "nota": 10 },

                { "materia": "Práticas Profissionais e Empreendedoras", "tipo": "Prova", "nota": 8 },
                { "materia": "Práticas Profissionais e Empreendedoras", "tipo": "Trabalho", "nota": 10 },
                { "materia": "Práticas Profissionais e Empreendedoras", "tipo": "Participção", "nota": 10 },

                { "materia": "Desenvolvimento de Aplicativos", "tipo": "Prova", "nota": 7 },
                { "materia": "Desenvolvimento de Aplicativos", "tipo": "Trabalho", "nota": 7 },
                { "materia": "Desenvolvimento de Aplicativos", "tipo": "Participção", "nota": 7 }
            ]
        },

    ]
}
