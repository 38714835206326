import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/prism';

import Sidebar from '../../../Components/SideBar/MiniDrawer';

function CssClassPseudoClass() {

    const classeCss = `
    .caixa {
        width: 200px;
        height: 200px;
        background-color: blue;
    }`;

    const classeHtml = `<div class="caixa"></div>`;

    const hoverCss = `
    .caixa:hover {
        width: 200px;
        height: 200px;
        background-color: red;
    }`;

    return (
        <div>
            <Sidebar>
                <h1>Classe e Pseudo-classe</h1>


                <p><b>Classe (Class):</b></p>


                <ul>
                    <li><p>Uma classe em HTML e CSS é um identificador que você pode aplicar a um ou mais elementos HTML para estilizá-los de maneira consistente.</p></li>
                    <li><p>No HTML, você define uma classe usando o atributo <b>class</b> em uma tag.</p></li>
                    <li><p>No CSS, você estiliza elementos com uma classe usando um seletor que começa com um ponto <b>(.)</b> seguido pelo nome da classe.</p></li>
                    <li><p>As classes permitem que você aplique o mesmo conjunto de estilos a múltiplos elementos sem ter que repetir o código CSS.</p></li>
                </ul>

                <p>Exemplo de uso em CSS:</p>
                <SyntaxHighlighter language="css" style={darcula}>
                    {classeCss}
                </SyntaxHighlighter>
                <br />

                <p>Exemplo de uso em HTML:</p>
                <SyntaxHighlighter language="html" style={darcula}>
                    {classeHtml}
                </SyntaxHighlighter>
                <br />


                <p><b>Pseudo-classe (Pseudo-class):</b></p>

                <ul>
                    <li>
                        <p>Uma pseudo-classe é uma palavra-chave que especifica um estado especial de um elemento HTML. Elas permitem estilizar um elemento com base em um estado
                            que não pode ser representado por uma classe.</p>
                    </li>
                    <li><p>Pseudo-classes são precedidas por dois pontos <b>(:)</b> e aplicadas a um seletor.</p></li>
                    <li><p>Exemplos comuns de pseudo-classes incluem <b>:hover</b> (quando o mouse passa sobre o elemento),
                        <b>:active</b> (quando o elemento está ativo), e <b>:first-child</b>
                        (quando o elemento é o primeiro filho de seu pai).</p>
                    </li>
                </ul>
                <SyntaxHighlighter language="css" style={darcula}>
                    {hoverCss}
                </SyntaxHighlighter>
                <br />
            </Sidebar>
        </div>
    );
}

export default CssClassPseudoClass;



