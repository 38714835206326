import styled from "styled-components";
// <div >
//   <h1 className="text-3xl font-bold underline">
//     Hello Home Page
//   </h1>
// </div>

export const ButtonContainer = styled.div`
    background-color: #f7f2f2;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: flex-start;
`;
