import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { useNavigate } from 'react-router-dom';


import Button from '../../../Components/Button/ButtonMaterial';
import Sidebar from '../../../Components/SideBar/MiniDrawer';

function JsVetores() {

    const navigate = useNavigate();

    const criandoVetor = `
    // Vetor Vazio
    const array1 = [];
    // Vetor preenchido
    const array2 = [1, 2, 3, 4, 5];

    // Vetor preenchido
    const array3 = ["Morango", "Banana", "Melancia"];
    `;

    const acessandoVetor = `
    const array = [1, 2, 3, 4, 5];
    console.log(array[0]); // 1
    console.log(array[3]); // 4`;


    const ModificandoElementos = `
    const array = [1, 2, 3, 4, 5];
    array[0] = 10;
    console.log(array); // [10, 2, 3, 4, 5]`;

    const pushEpop = `
    const array = [1, 2, 3];
    array.push(4);
    console.log(array); // [1, 2, 3, 4]
    
    array.pop();
    console.log(array); // [1, 2, 3]`;

    const shiftEunshift = `
    const array = [1, 2, 3];
    array.unshift(0);
    console.log(array); // [0, 1, 2, 3]

    array.shift();
    console.log(array); // [1, 2, 3]`;

    const removerElementos = `
    const array = [1, 2, 3, 4, 5];
    array.splice(2, 1); // Remove 1 elemento a partir do índice 2
    console.log(array); // [1, 2, 4, 5]`;

    const adicionarElementos = `
    const array = [1, 2, 3, 4, 5];
    array.splice(2, 0, 6); // Adiciona o número 6 na posição 2
    console.log(array); // [1, 2, 6, 3, 4, 5]`;


    return (
        <div>
            <Sidebar>
                <h1>Vetores</h1>
                <p>Vetores, ou arrays, são uma das estruturas de dados mais comuns e úteis em JavaScript.
                    Eles permitem armazenar e manipular uma coleção de elementos, que podem ser de qualquer
                    tipo de dado, incluindo números, strings, objetos, ou mesmo outros arrays. Vamos explorar
                    os principais métodos e operações que você pode realizar com arrays em JavaScript.
                </p>
                <h3>Criação de Arrays</h3>
                <p>Você pode criar arrays de várias maneiras em JavaScript:</p>
                <SyntaxHighlighter language="javascript" style={darcula}>
                    {criandoVetor}
                </SyntaxHighlighter>
                <br />

                <h3>Acessando Elementos</h3>
                <p>Você pode acessar elementos de um array usando a notação de colchetes:</p>
                <SyntaxHighlighter language="javascript" style={darcula}>
                    {acessandoVetor}
                </SyntaxHighlighter>
                <br />


                <h3>Modificando Elementos</h3>
                <p>Você pode modificar elementos do array da mesma forma que acessa:</p>
                <SyntaxHighlighter language="javascript" style={darcula}>
                    {ModificandoElementos}
                </SyntaxHighlighter>
                <br />


                <h3>Métodos Comuns de Arrays</h3>
                <p>Aqui estão alguns métodos comuns para manipulação de arrays: </p>
                <b>push e pop</b>
                <p><b>Adicionam</b> e
                    <b>removem</b> elementos do <b>final</b> do array, respectivamente:
                </p>
                <SyntaxHighlighter language="javascript" style={darcula}>
                    {pushEpop}
                </SyntaxHighlighter>
                <br />

                <b>unshift e shift</b>
                <p><b>Adicionam</b> e <b>removem</b> elementos do <b>início</b> do array, respectivamente:</p>
                <SyntaxHighlighter language="javascript" style={darcula}>
                    {shiftEunshift}
                </SyntaxHighlighter>
                <br />


                <b>Método splice</b>
                <p>O método splice pode ser usado para adicionar ou remover elementos de um array. Ele modifica o array original.</p>
                <b>Remover elementos</b>
                <SyntaxHighlighter language="javascript" style={darcula}>
                    {removerElementos}
                </SyntaxHighlighter>
                <br />
                <b>Adicionar elementos</b>
                <SyntaxHighlighter language="javascript" style={darcula}>
                    {adicionarElementos}
                </SyntaxHighlighter>
            </Sidebar>
        </div >
    );
}

export default JsVetores;



