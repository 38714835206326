import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { useNavigate } from 'react-router-dom';
import { ButtonContainer, } from './styles';

import Button from '../../../Components/Button/ButtonMaterial';
import Sidebar from '../../../Components/SideBar/MiniDrawer';

function PhpHome() {
    const navigate = useNavigate();


    function chamaoqueePhp() {
        navigate('/oqueephp');
    }
    function chamaCondicoesPhp() {
        navigate('/phpcondicoes');
    }
    function chamaFuncoes() {
        navigate('/phpfuncoes');
    }
    function chamaSwitch() {
        navigate('/phpswitch');
    }
    function chamaFuncoesMatematicas() {
        navigate('/funcoesmatematicas');
    }
    function chamaArraysAssociativosMatrizes() {
        navigate('/arraysassociativosmatrizes');
    }
    function chamaManipulandoArrays() {
        navigate('/phpmanipulandoarrays');
    }
    function chamaForForeach() {
        navigate('/phpforforeach');
    }
    function chamaPhpConexao() {
        navigate('/phpconexao');
    }
    
    
    function chamabotao() {
        console.log('chamabotao');
    }
    

    return (
        <div>
            <Sidebar>
                <h1>Seção PHP, aqui estão os códigos básicos em php</h1>

                <ButtonContainer>
                    {/* <Button texto='oi' /> */}
                    <Button texto='O que é PHP' onClick={chamaoqueePhp} setMarginRight='5px' setMarginBottom='5px' />
                    <Button texto='Condições' onClick={chamaCondicoesPhp} setMarginRight='5px' setMarginBottom='5px' />
                    <Button texto='Funções' onClick={chamaFuncoes} setMarginRight='5px' setMarginBottom='5px' />
                    <Button texto='Switch' onClick={chamaSwitch} setMarginRight='5px' setMarginBottom='5px' />
                    <Button texto='Funções Matemáticas' onClick={chamaFuncoesMatematicas} setMarginRight='5px' setMarginBottom='5px' />
                    <Button texto='Arrays, Associativos e Matrizes' onClick={chamaArraysAssociativosMatrizes} setMarginRight='5px' setMarginBottom='5px' />
                    <Button texto='Manipulando Arrays' onClick={chamaManipulandoArrays} setMarginRight='5px' setMarginBottom='5px' />
                    <Button texto='For e Foreach' onClick={chamaForForeach} setMarginRight='5px' setMarginBottom='5px' />
                    <Button texto='Conectar ao Banco' onClick={chamaPhpConexao} setMarginRight='5px' setMarginBottom='5px' />

                    
                </ButtonContainer>
            </Sidebar>
        </div>
    );
}

export default PhpHome;


