import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { useNavigate } from 'react-router-dom';


import Button from '../../../Components/Button/ButtonMaterial';
import Sidebar from '../../../Components/SideBar/MiniDrawer';

function JsVariaveis() {

    const navigate = useNavigate();

    const dadostemporarios = `
    var idade = 25;
    var nome = "Maria";
  `;

    const manipularvalores = `var saldo = 100;
    saldo = saldo - 50; // Atualiza o saldo`;

    const elementosdom = `var botao = document.getElementById('meuBotao');
    botao.addEventListener('click', function() {
        // Código para manipular o botão aqui
    });`;

    const dadosfuncoes = `function soma(a, b) {
        return a + b;
    }
    
    var resultado = soma(5, 3);
    console.log(resultado); // Saída: 8`;

    const guardarestados = `var menuAberto = false;

    function toggleMenu() {
        menuAberto = !menuAberto; // Inverte o estado do menu
        // Código para abrir ou fechar o menu aqui
    }`;

    const variaveisnumero = `var idade = 25;
var altura = 1.75;
    `;

    const variaveisstring = `var nome = "João";
var sobrenome = 'Silva';
    `;

    const variaveisbooleano = `var isAtivo = true;
var isAdmin = false;
        `;

    const variaveisarray = `var cores = ["vermelho", "azul", "verde"];
var numeros = [1, 2, 3, 4, 5];
        `;

    const variaveiobjeto = `var pessoa = {
        nome: "Maria",
        idade: 30,
        cidade: "São Paulo"
    };`;

    const variaveisindefinido = `var endereco;`;


    const variaveisnull = `var carro = null;`;

    return (
        <div>
            <Sidebar>
                <h1>Variáveis</h1>

                <p> As variáveis em JavaScript servem para armazenar e manipular dados dentro de um programa.
                    Elas fornecem um meio de guardar valores temporários ou permanentes que podem ser usados em diferentes partes do código.
                </p>

                <h2>Armazenar dados temporários:</h2>
                <p><b> Armazenar dados temporários:</b>As variáveis permitem armazenar valores temporários que podem ser usados em operações ou cálculos dentro de uma função ou bloco de código.
                </p>
                <SyntaxHighlighter language="javascript" style={darcula}>
                    {dadostemporarios}
                </SyntaxHighlighter>


                <p><b>Manipular valores:</b> As variáveis permitem que você altere os valores armazenados conforme necessário ao longo do código.</p>
                <SyntaxHighlighter language="javascript" style={darcula}>
                    {manipularvalores}
                </SyntaxHighlighter>

                <p><b>Referenciar elementos do DOM:</b> Em JavaScript para navegador, as variáveis são frequentemente usadas para armazenar
                    referências a elementos HTML para facilitar a manipulação deles.
                </p>
                <SyntaxHighlighter language="javascript" style={darcula}>
                    {elementosdom}
                </SyntaxHighlighter>

                <p><b>Passar dados entre funções:</b> Variáveis permitem passar dados entre diferentes partes do código, como argumentos para
                    funções ou valores de retorno.
                </p>
                <SyntaxHighlighter language="javascript" style={darcula}>
                    {dadosfuncoes}
                </SyntaxHighlighter>

                <p><b>Guardar estado:</b> As variáveis também podem ser usadas para manter o estado de um aplicativo ou de uma página da web.
                    Por exemplo, você pode usar uma variável para controlar se um menu está aberto ou fechado.
                </p>
                <SyntaxHighlighter language="javascript" style={darcula}>
                    {guardarestados}
                </SyntaxHighlighter>


                <h2>Tipos de Variáveis </h2>
                <p>Variáveis do tipo número</p>
                <SyntaxHighlighter language="javascript" style={darcula}>
                    {variaveisnumero}
                </SyntaxHighlighter>


                <p>Variáveis do tipo string</p>
                <SyntaxHighlighter language="javascript" style={darcula}>
                    {variaveisstring}
                </SyntaxHighlighter>

                <p>Variáveis do tipo booleano</p>
                <SyntaxHighlighter language="javascript" style={darcula}>
                    {variaveisbooleano}
                </SyntaxHighlighter>

                <p>Variáveis do tipo array</p>
                <SyntaxHighlighter language="javascript" style={darcula}>
                    {variaveisarray}
                </SyntaxHighlighter>

                <p>Variáveis do tipo objeto</p>
                <SyntaxHighlighter language="javascript" style={darcula}>
                    {variaveiobjeto}
                </SyntaxHighlighter>

                <p>Variáveis do tipo indefinido (undefined)</p>
                <SyntaxHighlighter language="javascript" style={darcula}>
                    {variaveisindefinido}
                </SyntaxHighlighter>

                <p>Variáveis do tipo nulo (null)</p>
                <SyntaxHighlighter language="javascript" style={darcula}>
                    {variaveisnull}
                </SyntaxHighlighter>
            </Sidebar>
        </div>
    );
}

export default JsVariaveis;


