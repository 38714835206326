import React from 'react';
import { ContainerStyled, ButtonStyled } from './styles';
import Sidebar from '../../../Components/SideBar/MiniDrawer';

function HtmlExemploSite() {

    const downloadFile = () => {
        // URL do arquivo que você quer baixar
        const fileUrl = 'https://mateusnobre.com/downloads/exemplo.rar';

        // Criar um elemento de link temporário
        const link = document.createElement('a');
        link.href = fileUrl;

        // Definir o atributo 'download' para que o navegador faça o download ao invés de navegar para o URL
        link.download = 'arquivo.rar';

        // Adicionar o link ao corpo do documento
        document.body.appendChild(link);

        // Simular o clique no link para iniciar o download
        link.click();

        // Limpar o elemento de link depois do download
        document.body.removeChild(link);
    }


    return (
        <div>
            <Sidebar>
                <h1>Exemplo de site</h1>
                <p>Exemplo de um pequeno site para você customizar em html</p>

                <ContainerStyled>
                    <ButtonStyled onClick={downloadFile}>Baixar Arquivo</ButtonStyled>
                </ContainerStyled>


            </Sidebar>
        </div>
    );
}

export default HtmlExemploSite;


