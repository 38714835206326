import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { useNavigate } from 'react-router-dom';


import Button from '../../../Components/Button/ButtonMaterial';
import Sidebar from '../../../Components/SideBar/MiniDrawer';

function PhpFuncoes() {
    const navigate = useNavigate();

    const codigo = `
    <?php
    // Definindo uma função chamada "saudacao"
    function saudacao($nome) {
        echo "Olá, $nome! Bem-vindo ao nosso site.";
    }
    
    // Chamando a função e passando um argumento
    saudacao("João");
    ?>
  `;
    const codigoDois = `<?php
    // Definindo uma função que retorna o quadrado de um número
    function quadrado($num) {
        return $num * $num;
    }
    
    // Chamando a função e exibindo o resultado
    $resultado = quadrado(5);
    echo "O quadrado de 5 é: $resultado";
    ?>`;


    return (
        <div>
            <Sidebar>
                <h1>Funções em PHP</h1>


                <p>
                    Em PHP, as funções são blocos de código que podem ser chamados e executados em qualquer lugar do seu script PHP.
                    Elas são úteis para organizar e reutilizar código, tornando-o mais legível e fácil de manter. Você pode criar suas próprias
                    funções em PHP ou usar funções pré-definidas pela linguagem.
                </p>
                <SyntaxHighlighter language="php" style={darcula}>
                    {codigo}
                </SyntaxHighlighter>
                Neste exemplo:

                <p><b>function saudacao($nome):</b> Define a função chamada "saudacao" que aceita um parâmetro <b>$nome</b>.</p>
                <p><b>echo "Olá, $nome! Bem-vindo ao nosso site.";:</b>  Dentro da função, exibe uma mensagem de saudação que inclui o nome passado como argumento.</p>
                <p><b>saudacao("João");:</b> Chama a função "saudacao" e passa o nome "João" como argumento.</p>

                <SyntaxHighlighter language="php" style={darcula}>
                    {codigoDois}
                </SyntaxHighlighter>

                <p>Neste exemplo, a função "quadrado" retorna o quadrado do número passado como argumento. O resultado é armazenado em uma variável <b>$resultado</b> e exibido na tela.</p>

            </Sidebar>
        </div>
    );
}

export default PhpFuncoes;


