import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { useNavigate } from 'react-router-dom';


import Button from '../../../Components/Button/ButtonMaterial';
import Sidebar from '../../../Components/SideBar/MiniDrawer';

function HtmlImagensMultimidia() {
    const navigate = useNavigate();

    const Imagens = `
    <!DOCTYPE html>
    <html lang="en">
    <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Exemplo de Imagem</title>
    </head>
    <body>
    
    <!-- Adicionando uma imagem -->
    <img src="caminho/para/sua/imagem.jpg" alt="Descrição da imagem">
    
    </body>
    </html>
  `;

    const Videos = `
  <!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Exemplo de Vídeo</title>
</head>
<body>

<!-- Adicionando um vídeo -->
<video width="320" height="240" controls>
  <source src="caminho/para/seu/video.mp4" type="video/mp4">
  Seu navegador não suporta o elemento de vídeo.
</video>

</body>
</html>
  `;

    const Audios = `
  <!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Exemplo de Áudio</title>
</head>
<body>

<!-- Adicionando um áudio -->
<audio controls>
  <source src="caminho/para/seu/audio.mp3" type="audio/mpeg">
  Seu navegador não suporta o elemento de áudio.
</audio>

</body>
</html>`;

    return (
        <div>
            <Sidebar>
                <h1>Adicionando uma Imagem:</h1>
                <p>
                    Para adicionar uma imagem em HTML, você pode usar o elemento <b>{'<img>'}</b> e definir o atributo <b>src</b> com o caminho da imagem que deseja exibir. Aqui está um exemplo:
                </p>
                <p>
                    Substitua <b>"caminho/para/sua/imagem.jpg"</b> pelo caminho real da sua imagem no servidor.
                    O atributo <b>alt</b> é opcional e fornece uma descrição alternativa para a imagem, útil para acessibilidade e quando a imagem não pode ser exibida.
                </p>

                <SyntaxHighlighter language="html" style={darcula}>
                    {Imagens}
                </SyntaxHighlighter>
                <br />
                <h1>Adicionando um Vídeo:</h1>
                <p>
                    Para adicionar um vídeo em HTML5, você pode usar o elemento <b>{'<video>'}</b> e definir o atributo <b>src</b> com o caminho do vídeo que deseja exibir. Aqui está um exemplo:
                </p>
                <p>
                    Substitua <b>"caminho/para/seu/video.mp4"</b> pelo caminho real do seu vídeo no servidor. O elemento <b>{'<video>'}</b> permite adicionar vários formatos de vídeo para garantir
                    a compatibilidade com diferentes navegadores. O atributo <b>controls</b> adiciona controles de reprodução padrão ao vídeo.
                </p>
                <SyntaxHighlighter language="html" style={darcula}>
                    {Videos}
                </SyntaxHighlighter>
                <br />
                <h1>Adicionando um Áudio:</h1>
                <p>
                    Para adicionar um arquivo de áudio em HTML5, você pode usar o elemento <b>{'<audio>'}</b> e definir o atributo <b>src</b> com o caminho do arquivo de áudio que deseja exibir. Aqui está um exemplo:

                </p>
                <p>
                    Substitua <b>"caminho/para/seu/audio.mp3"</b> pelo caminho real do seu arquivo de áudio no servidor. O elemento <b>{'<audio>'}</b> também permite adicionar vários
                    formatos de áudio para garantir a compatibilidade com diferentes navegadores. O atributo <b>controls</b> adiciona controles de reprodução padrão ao áudio.
                </p>
                <SyntaxHighlighter language="html" style={darcula}>
                    {Audios}
                </SyntaxHighlighter>
            </Sidebar>
        </div>
    );
}

export default HtmlImagensMultimidia;


