import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { useNavigate } from 'react-router-dom';


import Button from '../../../Components/Button/ButtonMaterial';
import Sidebar from '../../../Components/SideBar/MiniDrawer';

function HtmlTags() {
    const navigate = useNavigate();

    const codigo = `
    <!DOCTYPE html>
    <html lang="pt-BR">
    <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Exemplo de Tags HTML</title>
    </head>
    <body>
        <h1>Título Principal</h1>
        
        <p>Este é um parágrafo de texto.</p>
        
        <a href="https://www.exemplo.com">Link para exemplo</a>
        
        <img src="imagem.jpg" alt="Descrição da imagem">
    </body>
    </html>
  `;



    return (
        <div>
            <Sidebar>
                <h1>Tags em Html</h1>


                <p>
                    As tags em HTML são elementos que são usados para marcar diferentes
                    partes de um documento HTML para definir sua estrutura e conteúdo.
                    Em outras palavras, as tags são as construções básicas que compõem um
                    documento HTML e são interpretadas pelos navegadores da web para renderizar
                    o conteúdo da página.
                </p>

                <p>
                    Cada tag HTML é cercada por colchetes angulares <b>`{'<'}` e  `{'>'}`</b> e
                    consiste em um nome de tag que define o tipo de elemento HTML, como <b>{' <p>'}</b> para parágrafos, <b>{'<h1> '}</b>para
                    cabeçalhos de nível 1, <b>{'<div>'}</b> para divisões, entre muitos outros.
                    Além disso, algumas tags podem ter atributos, que fornecem informações adicionais sobre
                    o elemento e podem ser usados para modificar seu comportamento ou aparência.
                </p>
                <SyntaxHighlighter language="html" style={darcula}>
                    {codigo}
                </SyntaxHighlighter>

                <p>Neste exemplo:</p>

                <p> <b>{'<html>, <head>, <title>, <body> '}:</b>  são tags que definem a estrutura básica do documento HTML.</p>

                <p><b>{'<h1>, <p>, <a>, <img>'}: </b>são tags que definem diferentes tipos de conteúdo, como cabeçalhos, parágrafos, links e imagens.</p>
                <p><b>{'<meta>'}: </b> é uma tag usada para fornecer metadados sobre o documento, como o conjunto de caracteres utilizado e a escala de visualização inicial.</p>
                <p><b>{'<a> e <img>'}: </b> são exemplos de tags que possuem atributos (href, src, alt, etc.).</p>
                <p>Em resumo, as tags HTML são essenciais para estruturar e organizar o conteúdo de uma página web e são fundamentais para o desenvolvimento de documentos HTML.</p>



                <h2>Mais exemplos de tags:</h2>
                <p><b>Tags de Cabeçalho:</b></p>
                <ul>
                    <li><b>{'<h1>, <h2>, <h3>, <h4>, <h5>, <h6>'}:</b> Usadas para criar cabeçalhos de diferentes níveis de importância.</li>
                </ul>
                <p><b>Tags de Parágrafo:</b></p>
                <ul>
                    <li><b>{'<p>'}:</b> Usada para definir um parágrafo de texto.</li>
                </ul>

                <p><b>Tags de Lista:</b></p>
                <ul>
                    <li><b>{'<ul>'}:</b> Usada para criar uma lista não ordenada.</li>
                    <li><b>{'<ol>'}:</b> Usada para criar uma lista ordenada.</li>
                    <li><b>{'<li>'}:</b> Usada para definir itens de lista dentro de <b>{'<ul>'}</b> ou <b>{'<ol>'}</b>.</li>
                </ul>
            </Sidebar>
        </div>
    );
}

export default HtmlTags;


