import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { useNavigate } from 'react-router-dom';


import Button from '../../../Components/Button/ButtonMaterial';
import Sidebar from '../../../Components/SideBar/MiniDrawer';

function JsClasseDom() {

    const navigate = useNavigate();

    const html = `
    <div id="minhaDiv" class="vermelho"></div>
    <button onclick="trocarCor()">Trocar Cor</button>`;

    const css = `
    .vermelho {
        width: 200px;
        height: 20px;
        background-color: red;
    }
    
    .verde {
        width: 200px;
        height: 20px;
        background-color: green;
    }`;

    const javascript = `
    function trocarCor() {
        var div = document.getElementById('minhaDiv');
        // Remove a classe 'vermelho' e adiciona a classe 'verde'
        div.classList.remove('vermelho');
        div.classList.add('verde');
    }`;

    return (
        <div>
            <Sidebar>
                <h1> Formulário e DOM </h1>

                <p> Aqui está um exemplo de como você pode usar JavaScript para trocar a classe de uma {'<div>'} para alterar a cor de fundo de vermelho para verde quando um botão é clicado</p>

                <p>Neste exemplo, temos uma {'<div>'} com o ID "minhaDiv" que inicialmente tem a classe "vermelho", que define o fundo como vermelho. Quando o botão é clicado, a função </p>

                <ul>
                    <li><b>trocarCor()</b> é chamada. Dentro desta função, usamos </li>
                    <li><b>document.getElementById('minhaDiv')</b> para obter a referência à div, </li>
                    <li><b>classList.remove('vermelho')</b> para remover a classe "vermelho" e </li>
                    <li><b>classList.add('verde')</b> para adicionar a classe "verde". Como resultado, a cor de fundo da div muda de vermelho para verde.</li>
                </ul>

                <p>HTML</p>
                <SyntaxHighlighter language="html" style={darcula}>
                    {html}
                </SyntaxHighlighter>
                <br />

                <p>CSS</p>
                <SyntaxHighlighter language="css" style={darcula}>
                    {css}
                </SyntaxHighlighter>
                <br />

                <p>JavaScript</p>
                <SyntaxHighlighter language="javascript" style={darcula}>
                    {javascript}
                </SyntaxHighlighter>
                <br />


            </Sidebar>
        </div>
    );
}

export default JsClasseDom;



