import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { useNavigate } from 'react-router-dom';


import Button from '../../../Components/Button/ButtonMaterial';
import Sidebar from '../../../Components/SideBar/MiniDrawer';

function JsComparacao() {


    const codigo = `// Operadores de comparação
    let x = 5;
    let y = 10;
    console.log(x == y);  // false
    console.log(x !== y); // true
    
    // Operadores lógicos
    let a = 20;
    let b = 30;
    console.log(a > 10 && b < 40); // true
    console.log(a < 10 || b > 40); // false
    
    // Operador ternário
    let idade = 18;
    let status = (idade >= 18) ? 'Adulto' : 'Menor de idade';
    console.log(status); // 'Adulto'
    
    // Operador Nullish Coalescing
    let valorPadrao = null;
    let valor = valorPadrao ?? 'Valor padrão';
    console.log(valor); // 'Valor padrão', pois valorPadrao é null`;


    return (
        <div>
            <Sidebar>
                <h1>Operadores de Comparação</h1>

                <p>Em JavaScript, os operadores de condição são utilizados para realizar comparações e controlar o fluxo do programa com base no resultado dessas comparações.
                    Aqui estão os principais operadores de condição em JavaScript:
                </p>

                <h3>Operadores de Comparação</h3>
                <ul>
                    <li> <b>{'=='}</b> Igual a (com coerção de tipo).</li>
                    <li><b>{'=== '}</b> Igual a (sem coerção de tipo).</li>
                    <li><b>{'!= '}</b> Diferente de (com coerção de tipo).</li>
                    <li><b>{'!=='}</b> Diferente de (sem coerção de tipo).</li>
                    <li><b>{'> '}</b> Maior que.</li>
                    <li><b>{'<'}</b> Menor que.</li>
                    <li><b>{'>='}</b> Maior ou igual a.</li>
                    <li><b>{'<='}</b> Menor ou igual a.</li>
                </ul>

                <h3>Operadores lógicos</h3>
                <ul>
                    <li> <b>{'&&'}</b> E lógico.</li>
                    <li><b>{'||'}</b> OU lógico.</li>
                    <li><b>{'!'}</b> NÃO lógico (negativo).</li>
                </ul>

                <h3>Operador Ternário</h3>
                <ul>
                    <li> <b>{'? '}</b> <b>(Ternário)</b>: Também conhecido como operador condicional, é uma forma abreviada de uma declaração <b>if...else</b>. Sintaxe:
                        <b>condição ? expressão_verdadeira : expressão_falsa</b>.
                    </li>
                </ul>

                <h3>Operador Nullish Coalescing</h3>
                <li><b>{'??:'}</b> Retorna o operando à direita quando o operando à esquerda é <b>null</b> ou <b>undefined</b>.
                    É útil para fornecer um valor padrão quando o valor original é nulo ou indefinido.
                </li>

                <h3>Exemplo de uso:</h3>
                <SyntaxHighlighter language="javascript" style={darcula}>
                    {codigo}
                </SyntaxHighlighter>

            </Sidebar>
        </div>
    );
}

export default JsComparacao;


