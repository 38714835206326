import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { useNavigate } from 'react-router-dom';


import Button from '../../../Components/Button/ButtonMaterial';
import Sidebar from '../../../Components/SideBar/MiniDrawer';

function PhpArraysAssociativosMatrizes() {
    const navigate = useNavigate();

    const Array = `<?php
    // Definindo um array indexado
    $frutas = array("Maçã", "Banana", "Laranja");
    
    // Adicionando um elemento ao final do array
    $frutas[] = "Morango";
    
    // Acessando elementos do array
    echo "A segunda fruta é: " . $frutas[1] . "<br>";
    
    // Deletando um elemento do array
    unset($frutas[0]); // Remove a maçã do array
    
    // Imprimindo o array após a remoção
    echo "Após remover a maçã, o array de frutas é: ";
    print_r($frutas);
    ?>`;

    const Associativo = `<?php
    // Definindo um array associativo
    $aluno = array("nome" => "João", "idade" => 20, "nota" => 8.5);
    
    // Adicionando um novo elemento ao array associativo
    $aluno["cidade"] = "São Paulo";
    
    // Acessando elementos do array associativo
    echo "O nome do aluno é: " . $aluno["nome"] . "<br>";
    
    // Deletando um elemento do array associativo
    unset($aluno["idade"]); // Remove a chave "idade" do array
    
    // Imprimindo o array associativo após a remoção
    echo "Após remover a idade, os dados do aluno são: ";
    print_r($aluno);
    ?>`;

    const Matrizes = `<?php
    // Definindo uma matriz (array multidimensional)
    $matriz = array(
        array(1, 2, 3),
        array(4, 5, 6),
        array(7, 8, 9)
    );
    
    // Adicionando um novo array ao final da matriz
    $matriz[] = array(10, 11, 12);
    
    // Acessando elementos da matriz
    echo "O elemento na segunda linha e terceira coluna é: " . $matriz[1][2] . "<br>";
    
    // Deletando um array da matriz
    unset($matriz[0]); // Remove o primeiro array da matriz
    
    // Imprimindo a matriz após a remoção
    echo "Após remover o primeiro array, a matriz é: <br>";
    print_r($matriz);
    ?>`;

    return (
        <div>
            <Sidebar>
                <h1>Arrays, Associativos e Matrizes em PHP</h1>
                <p>
                    Em PHP, os vetores são chamados de "arrays". Existem dois tipos principais de arrays em PHP: arrays indexados e arrays associativos.
                    Além disso, você pode criar matrizes (arrays multidimensionais) combinando arrays dentro de arrays.
                </p>
                <h3>Array Indexado:</h3>
                <ul>
                    <li><b>Uso:</b> Os arrays indexados são úteis quando você precisa de uma lista ordenada de elementos, acessíveis por números inteiros (índices).</li>
                    <li><b>Quando usar:</b> Use arrays indexados quando precisar armazenar uma coleção de elementos simples em uma ordem específica e acessá-los por posição numérica.</li>
                    <li><b>Exemplo de Uso:</b> Lista de itens de um menu, lista de nomes de usuários, etc.</li>
                </ul>
                <SyntaxHighlighter language="php" style={darcula}>
                    {Array}
                </SyntaxHighlighter>

                <h3>Array Associativo:</h3>
                <ul>
                    <li><b>Uso:</b> Os arrays associativos são úteis quando você precisa associar chaves (strings) a valores (dados).</li>
                    <li><b>Quando usar:</b> Use arrays associativos quando precisar armazenar pares chave-valor, onde a chave é uma string descritiva e o valor é algum tipo de dado relacionado.</li>
                    <li><b>Exemplo de Uso:</b> Dados de um usuário (nome, idade, e-mail), configurações do site (cor de fundo, tamanho da fonte), etc.</li>
                </ul>
                <SyntaxHighlighter language="php" style={darcula}>
                    {Associativo}
                </SyntaxHighlighter>

                <h3>Matriz (Array Multidimensional):</h3>
                <ul>
                    <li><b>Uso:</b> As matrizes são úteis quando você precisa armazenar dados em múltiplas dimensões, como em uma grade ou tabela.</li>
                    <li><b>Quando usar:</b> Use matrizes quando precisar organizar dados em linhas e colunas, ou em estruturas mais complexas.</li>
                    <li><b>Exemplo de Uso:</b> Planilhas de dados, grades de jogos, tabelas de multiplicação, etc.</li>
                </ul>
                <SyntaxHighlighter language="php" style={darcula}>
                    {Matrizes}
                </SyntaxHighlighter>

            </Sidebar>
        </div>
    );
}

export default PhpArraysAssociativosMatrizes;


