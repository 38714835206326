import styled from "styled-components";
// <div >
//   <h1 className="text-3xl font-bold underline">
//     Hello Home Page
//   </h1>
// </div>

export const ImgContainer = styled.div`
    width: 100%;
    height: 500px;
    // background-color: red;
    overflow: hidden;
    margin-bottom: 15px;
`;
export const ImgStyled = styled.img`
    width: 50%;
    height: 100%; 
    display: block;
`;
export const SecondImgStyled = styled.img`
    width: 100%;
    height: 100%; 
    display: block;
`;