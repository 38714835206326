import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { useNavigate } from 'react-router-dom';


import Button from '../../../Components/Button/ButtonMaterial';
import Sidebar from '../../../Components/SideBar/MiniDrawer';

function OqueeHtml() {
    const navigate = useNavigate();


    return (
        <div>
            <Sidebar>
                <h1>O que é HTML</h1>

                <div>
                    HTML, que significa HyperText Markup Language (Linguagem de Marcação de Hipertexto), é a linguagem padrão usada para criar páginas da web. É uma linguagem de marcação que define a estrutura e o conteúdo de uma página da web, incluindo texto, imagens, links, vídeos e outros elementos.

                    Em termos simples, o HTML funciona como o "esqueleto" de uma página da web, fornecendo a estrutura básica que define como o conteúdo será organizado e apresentado no navegador. Ele consiste em uma série de elementos chamados de "tags" que são usados para envolver e identificar diferentes partes do conteúdo.

                    Cada tag HTML tem uma função específica e pode conter atributos que fornecem informações adicionais sobre o elemento. Por exemplo, a tag <p/> é usada para criar parágrafos de texto, enquanto a tag <img /> é usada para inserir imagens.

                        O HTML é uma linguagem de marcação muito importante, pois forma a base de todas as páginas da web. É a primeira linguagem que os desenvolvedores web aprendem e é essencial para construir sites e aplicativos web.

                        Em resumo, o HTML é a linguagem usada para criar a estrutura e o conteúdo de páginas da web, permitindo que os desenvolvedores organizem e apresentem informações de forma eficaz para os usuários na internet.
                </div>
            </Sidebar>
        </div>
    );
}

export default OqueeHtml;


