import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/prism';

import Sidebar from '../../../Components/SideBar/MiniDrawer';

function CssCorBackgroundCor() {

    const css = `
    /* Estilizando um elemento de texto */
    .texto-estilizado {
        color: red; /* Define a cor do texto como vermelho */
        background-color: lightyellow; /* Define a cor de fundo como amarelo claro */
    }`;

    const html = `<p class="texto-estilizado">Este é um texto estilizado com CSS!</p>`;

    return (
        <div>
            <Sidebar>
                <h1>Color e background-color</h1>

                <p>
                    Aqui está um exemplo simples de como você pode usar as propriedades <b>color</b> e <b>background-color</b> em CSS para estilizar um elemento:
                </p>

                <SyntaxHighlighter language="css" style={darcula}>
                    {css}
                </SyntaxHighlighter>
                <br />

                <p>Neste exemplo, estamos estilizando um elemento com a classe <b>.texto-estilizado</b>. Definimos sua cor de texto como vermelho e sua cor de fundo como amarelo claro.
                    Você pode aplicar essa classe a qualquer elemento HTML que contenha texto para aplicar esses estilos. Por exemplo:
                </p>


                <SyntaxHighlighter language="html" style={darcula}>
                    {html}
                </SyntaxHighlighter>

                <p>Isso criará um parágrafo com texto vermelho sobre um fundo amarelo claro.</p>
            </Sidebar>
        </div>
    );
}

export default CssCorBackgroundCor;



