import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { useNavigate } from 'react-router-dom';


import Button from '../../../Components/Button/ButtonMaterial';
import Sidebar from '../../../Components/SideBar/MiniDrawer';

function JsDom() {

    const navigate = useNavigate();

    const alterarumconteudo = `
    // Exemplo de alteração de conteúdo de um elemento
    var elemento = document.getElementById('meuElemento');
    elemento.innerHTML = 'Novo conteúdo';`;

    const Eventos = `
    // Exemplo de manipulador de evento de clique
    var botao = document.getElementById('meuBotao');
    botao.addEventListener('click', function() {
        console.log('Botão clicado!');
    });`;

    const ManipulacaoDeEstilosCss = `
    // Exemplo de adição de classe CSS a um elemento
    var elemento = document.getElementById('meuElemento');
    elemento.classList.add('destaque');`;


    const ManipulacaoEstruturaPagina = `
    // Exemplo de criação de um novo elemento e sua adição à página
    var novoElemento = document.createElement('div');
    novoElemento.textContent = 'Novo elemento';
    document.body.appendChild(novoElemento);`;


    return (
        <div>
            <Sidebar>
                <h1> DOM (Document Object Model) </h1>

                <p>A DOM (Document Object Model) é uma interface de programação para documentos HTML, XML e SVG. Ela representa a estrutura hierárquica de um documento web como um conjunto de objetos,
                    que podem ser manipulados por programas ou scripts, como JavaScript.
                </p>

                <p>Cada parte da página - como textos, imagens, botões, etc. - é um ramo dessa árvore. Até mesmo coisas como cores, tamanhos de fonte e outros estilos são parte dessa árvore.
                    Cada uma dessas partes é chamada de "nó".
                </p>

                <p>Agora, aqui está a parte legal: com JavaScript, você pode mexer nessa árvore! Você pode pegar nós específicos (como um botão ou um pedaço de texto),
                    ver o que tem dentro deles e até mesmo mudar o que está dentro deles.
                </p>


                <p> Por exemplo, imagine que você queria mudar o texto de um botão na página. Com JavaScript, você pode encontrar esse botão na árvore (na DOM),
                    olhar o que está escrito nele e, em seguida, mudá-lo para o que você quiser!
                </p>

                <p><b>Manipulação de Elementos HTML:</b> JavaScript pode ser usado para selecionar elementos HTML na página usando métodos como <b>document.getElementById</b>, <b> document.querySelector</b>,
                    <b>document.querySelectorAll</b>, etc. Depois de selecionados, esses elementos podem ser manipulados dinamicamente, alterando seu conteúdo, estilo, atributos, etc.
                </p>
                <SyntaxHighlighter language="javascript" style={darcula}>
                    {alterarumconteudo}
                </SyntaxHighlighter>
                <br />

                <p><b>Eventos:</b> JavaScript é usado para lidar com eventos do usuário, como cliques de mouse, pressionamentos de teclas, submissões de formulários, etc.
                    Você pode anexar manipuladores de eventos a elementos HTML para responder a esses eventos.
                </p>
                <SyntaxHighlighter language="javascript" style={darcula}>
                    {Eventos}
                </SyntaxHighlighter>
                <br />


                <p><b>Manipulação de Estilos CSS:</b>JavaScript pode ser usado para manipular estilos CSS de elementos HTML. Você pode adicionar,
                    remover ou modificar classes CSS de elementos para alterar sua aparência.
                </p>
                <SyntaxHighlighter language="javascript" style={darcula}>
                    {ManipulacaoDeEstilosCss}
                </SyntaxHighlighter>
                <br />

                <p><b>Manipulação de Estrutura da Página:</b> JavaScript pode ser usado para adicionar, remover ou modificar elementos HTML na página.
                    Isso permite a criação de interfaces de usuário dinâmicas e interativas.
                </p>
                <SyntaxHighlighter language="javascript" style={darcula}>
                    {ManipulacaoEstruturaPagina}
                </SyntaxHighlighter>
                <br />

                <p>Esses são apenas alguns exemplos de como JavaScript e a DOM estão inter-relacionados. Juntos, eles formam a base para a criação de aplicativos da web interativos e dinâmicos.
                    JavaScript é usado para manipular a estrutura, o conteúdo e o comportamento de uma página web, enquanto a DOM fornece a interface que permite acessar e modificar esses elementos.
                </p>

            </Sidebar>
        </div>
    );
}

export default JsDom;



