import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { useNavigate } from 'react-router-dom';


import Button from '../../../Components/Button/ButtonMaterial';
import Sidebar from '../../../Components/SideBar/MiniDrawer';


function ColegioPlanoVersoesWindows() {
    const navigate = useNavigate();


    return (
        <div>
            <Sidebar>
                <h1>Versões do Windows</h1>
                <p>Aqui está uma introdução e três características de cada uma das principais versões do Windows, desde o MS-DOS até o Windows 11:</p>

                <h3>MS-DOS (1981)</h3>
                <p><b>Introdução:</b> Sistema operacional de linha de comando criado pela Microsoft e usado como base para o Windows.</p>

            </Sidebar>
        </div>
    );
}

export default ColegioPlanoVersoesWindows;


