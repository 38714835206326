import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { useNavigate } from 'react-router-dom';
import servicos from './Screenshot_1.png';
import adm from './Screenshot_2.png';
import phpmyadmin from './Screenshot_3.png';
import phpmyadminnovo from './Screenshot_4.png';
import criacaodebanco from './Screenshot_5.png';
import criacaodetabela from './Screenshot_6.png';
import configuracaoTabela from './Screenshot_7.png';
import finalizandoCriarTabela from './Screenshot_8.png';

import Button from '../../../Components/Button/ButtonMaterial';
import Sidebar from '../../../Components/SideBar/MiniDrawer';
import { ImgContainer, ImgStyled, SecondImgStyled } from './styles';

function SqlCriarBancoeTabelas() {
    const navigate = useNavigate();


    return (
        <div>
            <Sidebar>
                <h1>Criando um banco de dados e uma tabela no xampp </h1>
                <ImgContainer>
                    <h3>Passo 1: Iniciar o Servidor Apache e MySQL</h3>
                    <ImgStyled src={servicos} alt="serviços do xampp" />
                </ImgContainer>

                <ImgContainer>
                    <h3>Passo 2: Entrar no admin do MySQL</h3>
                    <ImgStyled src={adm} alt="Entrando no admin dos serviços" />
                </ImgContainer>

                <ImgContainer>
                    <h3>Passo 3: Painel do phpmyadmin</h3>
                    <ImgStyled src={phpmyadmin} alt="Entrando no admin dos serviços" />
                </ImgContainer>
                <p>Neste passo você pode notar que no lado esquerdo, existem tabelas <b>information_schema</b>,
                    <b>mysql</b>, <b>performance_schema</b>, <b>phpmyadmin</b> e <b>test</b>. Não vamos utilizar
                    elas por que são de configuração do próprio phpmyadmin.
                </p>

                <ImgContainer>
                    <h3>Passo 4: ir na opção novo para criar um novo banco de dados</h3>
                    <ImgStyled src={phpmyadminnovo} alt="Entrando no admin dos serviços" />
                </ImgContainer>

                <ImgContainer>
                    <h3>Passo 5: Página de criação de banco</h3>
                    <ImgStyled src={criacaodebanco} alt="criando banco" />
                </ImgContainer>
                <p>Nesta parte você vai ver dois inputs, no <b>primeiro</b>, você coloca o nome do <b>banco de dados</b>
                    e no <b>segundo</b> você coloca a sua <b>collation</b> eu particularmente uso a <b>utf8_general_ci</b>.
                </p>
                <p>Em bancos de dados, "collation" se refere ao conjunto de regras que determinam como os dados são
                    ordenados e comparados. Isso é especialmente importante em bancos de dados que suportam várias
                    linguagens e conjuntos de caracteres diferentes.
                </p>

                <ImgContainer>
                    <h3>Passo 6: Página de criação de tabela</h3>
                    <ImgStyled src={criacaodetabela} alt="criando tabela" />
                </ImgContainer>
                <p>Nesta parte você vai criar o <b>banco de dados</b>, você vai ver dois inputs, no <b>primeiro</b> é o nome da tabela, que vai ser
                    <b> tabelateste</b>, e o <b> segundo</b> a quantidade de colunas dela, no caso vamos trocar de <b>4</b> para <b>3</b>.</p>


                <ImgContainer>
                    <h3>Passo 7: Página de criação de tabela</h3>
                    <SecondImgStyled src={configuracaoTabela} alt="configurando a tabela" />
                </ImgContainer>
                <h3>Agora vamos preencher os campos.</h3>
                <h4>No <b>primeiro</b>:</h4>
                <ul>
                    <li><b>Nome:</b> id</li>
                    <li><b>Tipo:</b> INT</li>
                    <li>Marcar a opção: <b>A_I</b></li>
                </ul>

                <h4>No <b>segundo</b>:</h4>
                <ul>
                    <li><b>Nome:</b> nome</li>
                    <li><b>Tipo:</b> VARCHAR</li>
                    <li><b>Tamanho/Valores:</b> um valor que você precisa entre 0 a 255</li>
                </ul>

                <h4>No <b>terceiro</b>:</h4>
                <ul>
                    <li><b>Nome:</b> idade</li>
                    <li><b>Tipo:</b> DATE</li>
                </ul>

                <ImgContainer>
                    <h3>Passo 8: Finalizando</h3>
                    <SecondImgStyled src={finalizandoCriarTabela} alt="Finalizando criar tabela" />
                </ImgContainer>
                <p>Agora você vai ver está página mostrada na imagem mais acima, a tabela está criada e agora você pode inserir registros na aba SQL.</p>
            </Sidebar>
        </div>
    );
}

export default SqlCriarBancoeTabelas;


