import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { useNavigate } from 'react-router-dom';


import Button from '../../../Components/Button/ButtonMaterial';
import Sidebar from '../../../Components/SideBar/MiniDrawer';

function JsAdicionarPagina() {

    const navigate = useNavigate();

    const inline = `
    <!DOCTYPE html>
    <html lang="en">
    <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Exemplo</title>
        <script>
            // Seu código JavaScript aqui
            alert('Olá, mundo!');
        </script>
    </head>
    <body>
        <!-- Conteúdo HTML aqui -->
    </body>
    </html>
  `;

    const arquivoexterno = `<!DOCTYPE html>
  <html lang="en">
  <head>
      <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <title>Exemplo</title>
      <script src="script.js"></script>
  </head>
  <body>
      <!-- Conteúdo HTML aqui -->
  </body>
  </html>`;

  const eventhandlers = `<button onclick="alert('Clicou no botão!')">Clique-me</button>`;

    return (
        <div>
            <Sidebar>
                <h1>Adicionar no HTML</h1>

                <p>
                    Há várias formas de adicionar JavaScript a um documento HTML. Aqui estão algumas das formas mais comuns:
                </p>

                <h3>Inline JavaScript:</h3>
                <p>
                    Inline JavaScript: Você pode incluir código JavaScript diretamente no documento HTML usando a tag {'<script>'}
                    dentro do elemento {'<head>'} ou {'<body>'}. Por exemplo:
                </p>
                <SyntaxHighlighter language="javascript" style={darcula}>
                    {inline}
                </SyntaxHighlighter>

                <h3>Arquivo externo:</h3>

                <p>Arquivo externo: Você também pode incluir um arquivo JavaScript externo usando a tag {'<script>'}
                    com o atributo src, apontando para o arquivo JavaScript. Por exemplo:
                </p>
                <SyntaxHighlighter language="javascript" style={darcula}>
                    {arquivoexterno}
                </SyntaxHighlighter>

                <h3>Event Handlers: </h3>
                <p>Event Handlers: JavaScript também pode ser incluído diretamente em eventos HTML, como <b>onclick</b>, <b>onmouseover</b>, etc.
                    Isso não é recomendado para grandes blocos de código JavaScript, mas é útil para pequenas interações. Por exemplo:
                </p>
                <SyntaxHighlighter language="javascript" style={darcula}>
                    {eventhandlers}
                </SyntaxHighlighter>
                

            </Sidebar>
        </div>
    );
}

export default JsAdicionarPagina;


