import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { useNavigate } from 'react-router-dom';


import Button from '../../../Components/Button/ButtonMaterial';
import Sidebar from '../../../Components/SideBar/MiniDrawer';

function HtmlTabelas() {
    const navigate = useNavigate();

    const codigo = `
    <!DOCTYPE html>
    <html lang="en">
    <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Tabela HTML</title>
        <style>
            table {
                width: 100%;
                border-collapse: collapse;
            }
            th, td {
                border: 1px solid #ddd;
                padding: 8px;
                text-align: left;
            }
            th {
                background-color: #f2f2f2;
            }
            tr:nth-child(even) {
                background-color: #f2f2f2;
            }
        </style>
    </head>
    <body>
    
    <h2>Exemplo de Tabela</h2>
    
    <table>
      <thead>
        <tr>
          <th>Nome</th>
          <th>Idade</th>
          <th>Cidade</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>João</td>
          <td>25</td>
          <td>São Paulo</td>
        </tr>
        <tr>
          <td>Maria</td>
          <td>30</td>
          <td>Rio de Janeiro</td>
        </tr>
        <tr>
          <td>Carlos</td>
          <td>35</td>
          <td>Curitiba</td>
        </tr>
      </tbody>
    </table>
    
    </body>
    </html>
  `;


    return (
        <div>
            <Sidebar>
                <h1>Tabelas em Html</h1>

                <p>
                    Neste exemplo, criamos uma tabela simples com três colunas: Nome, Idade e Cidade. Utilizamos o
                    elemento <b>{'<thead>'}</b> para definir o cabeçalho da tabela e o elemento <b>{'<tbody>'}</b> para o corpo da tabela.
                    Estilizamos a tabela usando CSS para adicionar bordas, espaçamento interno e externo, e cores alternadas nas linhas.
                </p>

                <SyntaxHighlighter language="html" style={darcula}>
                    {codigo}
                </SyntaxHighlighter>


            </Sidebar>
        </div>
    );
}

export default HtmlTabelas;


