import * as React from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

interface ButtonMaterialInterface {
    texto: string;
    onClick?: () => void;
    color?: "inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning";
    setMarginTop?: string;
    setMarginLeft?: string;
    setMarginBottom?: string;
    setMarginRight?: string;
    type?: "button" | "submit" | "reset" | undefined;
}

export default function OutlinedButtons({
    texto,
    onClick,
    type,
    color,
    setMarginTop,
    setMarginLeft,
    setMarginBottom,
    setMarginRight }: ButtonMaterialInterface) {
    return (
        <Stack direction="row" spacing={2}>
            <Button type={type || 'button'} color={color} variant="outlined" onClick={onClick}
                style={{
                    marginTop: setMarginTop || 0,
                    marginLeft: setMarginLeft || 0,
                    marginBottom: setMarginBottom || 0,
                    marginRight: setMarginRight || 0,
                }}>
                {texto}
            </Button>
        </Stack>
    );
}