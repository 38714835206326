import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { useNavigate } from 'react-router-dom';


import Button from '../../../Components/Button/ButtonMaterial';
import Sidebar from '../../../Components/SideBar/MiniDrawer';

function ProvasSegundoAno() {
    const navigate = useNavigate();


    const handleDownloadArquiteturaSistemas = () => {
        const pdfUrl = './Analise_e_projetos_de_sistemas.pdf';  // Substitua pelo caminho correto do seu PDF
        const link = document.createElement('a');
        link.href = pdfUrl;
        link.download = 'Análise_e_projetos_de_sistemas.pdf';
        link.click();
    };

    const handleDownloadBancoDeDados = () => {
        const pdfUrl = './PDF_BANCO_DE_DADOS.pdf';  // Substitua pelo caminho correto do seu PDF
        const link = document.createElement('a');
        link.href = pdfUrl;
        link.download = 'Banco_de_dados.pdf';
        link.click();
    };

    const handleDownloadFrontEnd = () => {
        const pdfUrl = './FRONT_END.pdf';  // Substitua pelo caminho correto do seu PDF
        const link = document.createElement('a');
        link.href = pdfUrl;
        link.download = 'front_end.pdf';
        link.click();
    };

    return (
        <div>
            <Sidebar>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

                    <h1>Arquitetura</h1>
                    <Button texto='Baixar PDF' onClick={handleDownloadArquiteturaSistemas} setMarginRight='5px' />

                    <h1>Banco de dados</h1>
                    <Button texto='Baixar PDF' onClick={handleDownloadBancoDeDados} setMarginRight='5px' />

                    <h1>Front-End</h1>
                    <Button texto='Baixar PDF' onClick={handleDownloadFrontEnd} setMarginRight='5px' />

                </div>
            </Sidebar>
        </div>
    );
}

export default ProvasSegundoAno;


