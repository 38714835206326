import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { useNavigate } from 'react-router-dom';


import Button from '../../../Components/Button/ButtonMaterial';
import Sidebar from '../../../Components/SideBar/MiniDrawer';

function OqueeMysql() {
    const navigate = useNavigate();


    return (
        <div>
            <Sidebar>
                <h1>MySQL:</h1>

                <div>
                    MySQL é um dos sistemas de gerenciamento de banco de dados relacionais mais populares. Aqui estão alguns pontos-chave sobre o MySQL:
                </div>
                <div>
                    <ul>
                        <li><p><b>Open Source:</b> MySQL é um software de código aberto, o que significa que é gratuito para uso e distribuição. Ele é amplamente utilizado em várias aplicações, desde pequenos sites até grandes sistemas corporativos.</p></li>

                        <li><p><b>Desempenho:</b>  O MySQL é conhecido por seu desempenho eficiente, especialmente em ambientes de leitura intensiva. Ele oferece várias otimizações para consultas rápidas e eficientes.</p></li>

                        <li><p><b>Escalabilidade: </b>O MySQL é altamente escalável e pode ser usado em ambientes com grandes volumes de dados e alto tráfego. Ele suporta replicação, particionamento e outras técnicas para lidar com cargas de trabalho intensas.</p></li>

                        <li><p><b>Suporte à Transações: </b> MySQL suporta transações ACID (Atomicidade, Consistência, Isolamento, Durabilidade), o que garante que as operações de banco de dados sejam executadas de forma confiável e segura.
                        </p></li>

                        <li><p><b>Comunidade Ativa:</b>  Como uma das tecnologias de banco de dados mais populares, o MySQL possui uma grande comunidade de usuários e desenvolvedores. Isso significa que há uma abundância de recursos, documentação e suporte disponíveis.</p></li>
                    </ul>
                </div>
            </Sidebar>
        </div>
    );
}

export default OqueeMysql;


