import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { useNavigate } from 'react-router-dom';


import Button from '../../../Components/Button/ButtonMaterial';
import Sidebar from '../../../Components/SideBar/MiniDrawer';

function HtmlDisplayFlex() {
    const navigate = useNavigate();

    const codigo = `
    <!DOCTYPE html>
    <html lang="en">
    <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Layout com Flexbox</title>
        <style>
            /* Estilos para o contêiner flexível */
            .container {
                display: flex; /* Define o contêiner como flexível */
                justify-content: center; /* Alinha os itens ao centro no eixo principal (horizontal) */
                align-items: center; /* Alinha os itens ao centro no eixo secundário (vertical) */
                height: 300px; /* Altura do contêiner */
                border: 1px solid #ccc; /* Borda para visualização */
            }
    
            /* Estilos para os itens dentro do contêiner */
            .item {
                width: 100px; /* Largura dos itens */
                height: 100px; /* Altura dos itens */
                background-color: #007bff; /* Cor de fundo */
                margin: 10px; /* Margem entre os itens */
            }
        </style>
    </head>
    <body>
    
    <!-- Contêiner flexível -->
    <div class="container">
        <!-- Itens dentro do contêiner -->
        <div class="item"></div>
        <div class="item"></div>
        <div class="item"></div>
    </div>
    
    </body>
    </html>
  `;

    return (
        <div>
            <Sidebar>
                <h1>Display Flex</h1>
                <p>
                    O <b>display: flex</b> é uma propriedade do CSS que permite criar layouts flexíveis em um contêiner e controlar o
                    posicionamento e o alinhamento dos elementos filhos dentro desse contêiner. Com display: flex, os itens dentro do contêiner podem ser organizados automaticamente em uma linha ou em uma coluna, dependendo da direção principal especificada,
                    e você pode facilmente ajustar o tamanho, a ordem e o alinhamento dos elementos.
                </p>

                <p>
                    Ao usar <b>display: flex</b>, você tem acesso a uma série de propriedades que oferecem
                    controle detalhado sobre o layout flexível, como <b>justify-content</b> para alinhar
                    os itens ao longo do eixo principal, <b>align-items</b> para alinhar os itens ao
                    longo do eixo transversal, <b>flex-direction</b> para definir a direção principal
                    da flexbox (linha ou coluna), <b>flex-wrap</b> para controlar se os itens flexíveis
                    devem ser ajustados em uma única linha ou várias linhas, e muito mais.
                </p>
                <SyntaxHighlighter language="html" style={darcula}>
                    {codigo}
                </SyntaxHighlighter>

            </Sidebar>
        </div>
    );
}

export default HtmlDisplayFlex;


