import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { useNavigate } from 'react-router-dom';


import Button from '../../../Components/Button/ButtonMaterial';
import Sidebar from '../../../Components/SideBar/MiniDrawer';

function PhpForForeach() {
    const navigate = useNavigate();

    const codigoFor = `for ($i = 0; $i < 5; $i++) {
        echo "O valor de i é: $i <br>";
    }`;

    const codigoForeach = `$cores = array("vermelho", "verde", "azul");

    foreach ($cores as $cor) {
        echo "$cor <br>";
    }`;

    return (
        <div>
            <Sidebar>
                <h1>for e foreach em PHP</h1>


                <p>
                    Em PHP, tanto o <b>for</b> quanto o <b>foreach</b> são estruturas de controle de fluxo usadas para percorrer arrays ou iterar sobre um conjunto de elementos.
                    No entanto, eles têm diferentes usos e sintaxes. Aqui está uma explicação de cada um:
                </p>

                <h3>For Loop</h3>
                <p>O <b>for</b> é uma estrutura de repetição que permite executar um bloco de código um número específico de vezes.
                    É ideal quando você sabe exatamente quantas vezes deseja iterar sobre um conjunto de elementos.</p>
                <p>
                    Neste exemplo, <b>$i</b> é inicializado como 0, o loop continuará enquanto <b>$i</b>  for menor que 5 e a cada iteração <b>$i</b>  será incrementado em 1.
                </p>
                <SyntaxHighlighter language="php" style={darcula}>
                    {codigoFor}
                </SyntaxHighlighter>
                <h3>Foreach Loop</h3>
                <p>O <b>foreach </b> é usado especificamente para percorrer arrays e objetos. Ele itera sobre cada par chave-valor em um
                    array ou cada propriedade em um objeto.
                </p>
                <p>
                    Neste exemplo, <b>$cores</b> é um array contendo cores. O loop <b>foreach</b> itera sobre cada elemento do array e
                    a cada iteração <b>$cor</b> recebe o valor do elemento atual.
                </p>
                <SyntaxHighlighter language="php" style={darcula}>
                    {codigoForeach}
                </SyntaxHighlighter>

                <h3>Diferenças</h3>
                <ul>
                    <li><b>Sintaxe:</b> A sintaxe do for é mais complexa, pois requer a inicialização de uma variável de controle,
                        uma condição de saída e uma expressão de incremento. O foreach é mais simples, pois itera diretamente sobre os
                        elementos de um array ou as propriedades de um objeto.
                    </li>

                    <li><b>Uso: </b>O <b>for</b> é mais adequado quando você precisa iterar um número específico de vezes ou precisa de um controle
                        preciso sobre o índice de iteração. O <b>foreach</b> é mais conveniente quando você está trabalhando com arrays ou
                        objetos e deseja percorrer todos os seus elementos sem se preocupar com os índices.
                    </li>
                    <li><b>Arrays Associativos:</b> Enquanto o <b>for</b> pode ser usado para percorrer arrays associativos, o <b>foreach</b> é
                        mais conveniente para isso, pois lida automaticamente com as chaves e valores.
                    </li>
                </ul>
                <p>Em resumo, use <b>for</b> quando precisar de um controle preciso sobre o número de iterações ou índices, e use <b>foreach </b>
                    quando estiver lidando com arrays ou objetos e precisar percorrer todos os seus elementos de forma simples e direta.
                </p>
            </Sidebar>
        </div>
    );
}

export default PhpForForeach;


