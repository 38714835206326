import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { useNavigate } from 'react-router-dom';
import { ButtonContainer, } from './styles';

import Button from '../../../Components/Button/ButtonMaterial';
import Sidebar from '../../../Components/SideBar/MiniDrawer';

function EstudosProvas() {
    const navigate = useNavigate();


    function chamaSegundoAno() {
        navigate('/estudosprovassegundoano');
    }
    function chamaTerceiroAno() {
        navigate('/estudosprovasterceiroano');
    }
  

    function chamabotao() {
        console.log('chamabotao');
    }


    return (
        <div>
            <Sidebar>
                <h1>Conteúdos para as provas</h1>

                <ButtonContainer>
                    {/* <Button texto='oi' /> */}
                    <Button texto='3º Ano' onClick={chamaTerceiroAno} setMarginRight='5px' setMarginBottom='5px' />
                    <Button texto='2º Ano' onClick={chamaSegundoAno} setMarginRight='5px' setMarginBottom='5px' />
              


                </ButtonContainer>
            </Sidebar>
        </div>
    );
}

export default EstudosProvas;


