import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { useNavigate } from 'react-router-dom';


import Button from '../../../Components/Button/ButtonMaterial';
import Sidebar from '../../../Components/SideBar/MiniDrawer';

function OqueeJavascript() {
    const navigate = useNavigate();

    return (
        <div>
            <Sidebar>
                <h1>O que é Javascript</h1>

                <p>JavaScript é uma linguagem de programação de alto nível, interpretada, multi-paradigma e dinâmica, amplamente utilizada para desenvolvimento de aplicações web.
                    Ela é uma das principais tecnologias da web e é suportada por todos os navegadores modernos.
                </p>

                <p>Aqui estão alguns pontos-chave sobre JavaScript:</p>

                <ul>
                    <li><b>Linguagem de Script:</b> JavaScript é uma linguagem de script, o que significa que o código
                        é executado linha por linha, à medida que é encontrado. Ele não requer compilação antes da
                        execução, como em linguagens compiladas como C++ ou Java.
                    </li>

                    <li><b>Multi-paradigma:</b> JavaScript suporta múltiplos paradigmas de programação, incluindo programação
                        orientada a objetos, programação funcional e programação imperativa. Isso permite uma grande
                        flexibilidade na forma como os programas podem ser estruturados e escritos.
                    </li>
                    <li><b>Amplamente utilizado na web:</b> JavaScript é a linguagem de programação padrão para desenvolvimento
                        de páginas web interativas e dinâmicas. Ele é usado para adicionar interatividade,
                        manipular o DOM (Document Object Model), fazer requisições AJAX
                        (Asynchronous JavaScript and XML), criar animações, validar formulários e muito mais.
                    </li>
                    <li><b>Executado no lado do cliente e do servidor:</b> Originalmente, JavaScript era uma linguagem executada apenas no
                        lado do cliente, ou seja, no navegador do usuário. No entanto, com o advento de ambientes de execução do
                        JavaScript no servidor, como o Node.js, agora é possível usar JavaScript para desenvolver aplicativos de
                        back-end também.
                    </li>
                    <li><b>Sintaxe Simples e Flexível:</b> A sintaxe do JavaScript é relativamente simples e fácil de aprender,
                        especialmente para quem já tem familiaridade com outras linguagens de programação.
                        Ela possui uma sintaxe similar a outras linguagens como Java e C++, mas também possui suas próprias
                        peculiaridades.
                    </li>
                    <li><b>Ecossistema rico:</b> JavaScript possui um ecossistema rico de bibliotecas e frameworks, como React.js,
                        Angular.js, Vue.js, Express.js, entre outros, que facilitam o desenvolvimento de aplicações
                        web complexas e robustas.
                    </li>
                </ul>
                <p>JavaScript desempenha um papel fundamental na web moderna e é uma habilidade essencial para desenvolvedores web
                    e de software. Ele continuará a ser uma tecnologia relevante e poderosa no futuro, à medida que novas
                    aplicações e recursos web continuam a ser desenvolvidos.
                </p>

            </Sidebar>
        </div>
    );
}

export default OqueeJavascript;


