import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { useNavigate } from 'react-router-dom';


import Button from '../../../Components/Button/ButtonMaterial';
import Sidebar from '../../../Components/SideBar/MiniDrawer';

function PhpSwitch() {
    const navigate = useNavigate();

    const codigo = `
    <?php
$diaSemana = "Segunda";

switch ($diaSemana) {
    case "Domingo":
        echo "Hoje é Domingo.";
        break;
    case "Segunda":
        echo "Hoje é Segunda-feira.";
        break;
    case "Terça":
        echo "Hoje é Terça-feira.";
        break;
    case "Quarta":
        echo "Hoje é Quarta-feira.";
        break;
    case "Quinta":
        echo "Hoje é Quinta-feira.";
        break;
    case "Sexta":
        echo "Hoje é Sexta-feira.";
        break;
    case "Sábado":
        echo "Hoje é Sábado.";
        break;
    default:
        echo "Dia inválido.";
}
?>
  `;

    return (
        <div>
            <Sidebar>
                <h1>Switch em PHP</h1>


                <p>
                    Em PHP, a estrutura de controle <b>switch</b> é usada para executar uma ação com base no valor de uma expressão.
                    É semelhante a uma série de instruções <b>if</b> encadeadas, mas geralmente é mais eficiente para múltiplos casos de comparação.
                </p>
                <SyntaxHighlighter language="php" style={darcula}>
                    {codigo}
                </SyntaxHighlighter>
                Neste exemplo:

                <p><b>function saudacao($nome):</b> Define a função chamada "saudacao" que aceita um parâmetro <b>$nome</b>.</p>
                <p><b>echo "Olá, $nome! Bem-vindo ao nosso site.";:</b>  Dentro da função, exibe uma mensagem de saudação que inclui o nome passado como argumento.</p>
                <p><b>saudacao("João");:</b> Chama a função "saudacao" e passa o nome "João" como argumento.</p>

                <p>
                    Neste exemplo, o <b>switch</b> é usado para verificar o valor da variável <b>$diaSemana</b> e imprimir uma mensagem correspondente ao dia da semana.
                    Se o valor de <b>$diaSemana</b> corresponder a algum dos casos, a mensagem apropriada será exibida. Se nenhum dos casos corresponder, a mensagem <b>"Dia inválido."</b>
                    será exibida. O <b>break</b> é usado para sair do bloco de código do switch após a execução de um caso.
                </p>

                <h2>O switch e if são duas estruturas de controle em PHP (e em muitas outras linguagens de programação) que permitem controlar o fluxo do programa com base em condições. Aqui estão algumas diferenças principais entre eles:</h2>

                <h3>Uso de Condições Múltiplas:</h3>
                <ul>
                    <li>O <b>if</b> é geralmente usado quando há apenas uma condição a ser verificada.</li>
                    <li>O <b>switch</b> é usado quando há múltiplas condições a serem testadas, onde cada caso corresponde a um valor específico.</li>
                </ul>


                <h3> Legibilidade:</h3>
                <ul>
                    <li><b>if:</b> É usado quando você precisa verificar uma condição de maneira mais livre e flexível. É bom para condições complexas e com poucas opções.</li>
                    <li><b>switch:</b> É geralmente usado quando há muitos casos e cada caso pode levar a uma ação diferente. Pode ser mais legível em situações em que há várias condições a
                        serem verificadas e cada uma leva a uma ação específica.</li>
                </ul>

                <h3>Eficiência:</h3>
                <ul>
                    <li> Em alguns casos, o <b>switch</b> pode ser mais eficiente do que uma série de instruções if encadeadas,
                        especialmente quando há muitos casos a serem testados. Isso porque o <b>switch</b> pode otimizar a comparação de valores.</li>
                    <li> No entanto, em PHP, a diferença de desempenho entre <b>switch</b> e <b>if</b> geralmente não é significativa para um pequeno número de casos.</li>
                </ul>



                <h3>Flexibilidade:</h3>
                <ul>
                    <li>O <b>if</b> permite testar uma variedade de condições diferentes, incluindo condições complexas e combinações lógicas.</li>
                    <li>O <b>switch</b> é mais limitado e só pode ser usado para testar a igualdade de uma variável com uma série de valores.</li>
                </ul>
                Em resumo, <b>if</b> é mais flexível e pode lidar com uma variedade maior de condições, enquanto <b>switch</b> é mais adequado para situações onde você tem muitos casos
                e cada caso leva a uma ação específica. A escolha entre <b>if</b> e <b>switch</b> geralmente depende da situação específica e do estilo de codificação preferido.

            </Sidebar>
        </div>
    );
}

export default PhpSwitch;


