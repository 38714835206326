import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { useNavigate } from 'react-router-dom';
import { ButtonContainer, } from './styles';

import Button from '../../../Components/Button/ButtonMaterial';
import Sidebar from '../../../Components/SideBar/MiniDrawer';

function SqlHome() {
    const navigate = useNavigate();


    function chamaOqueeSql() {
        navigate('/oqueesql');
    }
    function chamaOqueeMysql() {
        navigate('/oqueemysql');
    }
    function chamaSqlCrud() {
        navigate('/sqlcrud');
    }
    function chamaSqlXampp() {
        navigate('/sqlxampp');
    }
    function chamaCriarBancoeTabelas() {
        navigate('/sqlcriarbancosetabelas');
    }

    function chamabotao() {
        console.log('chamabotao');
    }


    return (
        <div>
            <Sidebar>
                <h1>Seção HTML, aqui estão os códigos básicos em html</h1>

                <ButtonContainer>
                    {/* <Button texto='oi' /> */}
                    <Button texto='O que é SQL' onClick={chamaOqueeSql} setMarginRight='5px' setMarginBottom='5px' />
                    <Button texto='O que é MySQL' onClick={chamaOqueeMysql} setMarginRight='5px' setMarginBottom='5px' />
                    <Button texto='CRUD' onClick={chamaSqlCrud} setMarginRight='5px' setMarginBottom='5px' />
                    <Button texto='Xampp' onClick={chamaSqlXampp} setMarginRight='5px' setMarginBottom='5px' />
                    <Button texto='Criar Banco de dados e Tabelas' onClick={chamaCriarBancoeTabelas} setMarginRight='5px' setMarginBottom='5px' />

                </ButtonContainer>
            </Sidebar>
        </div>
    );
}

export default SqlHome;


