import React, { useState, useEffect } from 'react';
import { TableContainer, TdStyled, ThStyled } from './styles';


interface Aluno {
  nome: string;
  total: number;
  nota: number;
}

interface Props {
  data: Aluno[];
}

export function TabelaDeNotas({ data }: Props) {
  const [alunos, setAlunos] = useState<Aluno[]>([]);

  useEffect(() => {
    setAlunos(data);
  }, [data]);

  return (
    <TableContainer>
      <table border={1} style={{ width: '100%' }}>

        <thead>
          <tr>
            <ThStyled>Nome</ThStyled>
            <ThStyled>Total</ThStyled>
            <ThStyled>Nota</ThStyled>
          </tr>
        </thead>
        <tbody>
          {alunos.map((aluno, index) => (

            <tr key={index}>
              {index % 2 === 0 ? (
                <>
                  <TdStyled>{aluno.nome}</TdStyled>
                  <TdStyled>{aluno.total}</TdStyled>
                  <TdStyled>{(aluno.nota / aluno.total) * 100}%</TdStyled>
                </>

              ) : (
                <>
                  <TdStyled style={{backgroundColor: '#dcd9d9'}}>{aluno.nome}</TdStyled>
                  <TdStyled style={{backgroundColor: '#dcd9d9'}}>{aluno.total}</TdStyled>
                  <TdStyled style={{backgroundColor: '#dcd9d9'}}>{(aluno.nota / aluno.total) * 100}%</TdStyled>
                </>
              )}


            </tr>
          ))}
        </tbody>
      </table>
    </TableContainer>
  );
}

export default TabelaDeNotas;
