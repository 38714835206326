import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ProfilePhoto from './profile.jpg';

import Button from '../../Components/Button/ButtonMaterial';

import {
  ContainerStyled,
  SideBarStyled,
  ContentStyled,
  ImageStyled,
  H2Styled,
  Name,
  Surname,
  Content,
  ContentHeader,
  ContentBody,
  ContentButton,
  SideBarStyledOuter
} from './styles';


const Welcome: React.FC = () => {
  const navigate = useNavigate();
  const [selectedSection, setSelectedSection] = useState('About'); // Estado para controlar a seção selecionada
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };

    window.addEventListener('resize', handleResize);

    // Remove o event listener ao desmontar o componente para evitar vazamento de memória
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const goToScript = () => {
    navigate('/scriptd');
  }

  const goToHome = () => {
    navigate('/home');
  };

  return (
    <ContainerStyled>
      {windowSize.width >= 876 ? (
        <SideBarStyled>
          <ImageStyled src={ProfilePhoto} alt='Profile' />

          <H2Styled onClick={() => setSelectedSection('About')}>About</H2Styled>

          <H2Styled onClick={() => setSelectedSection('Education')}>Education</H2Styled>

        </SideBarStyled>
      ) : (
        <SideBarStyledOuter>
          <H2Styled onClick={() => setSelectedSection('About')}>About</H2Styled>

          <H2Styled onClick={() => setSelectedSection('Education')}>Education</H2Styled>
        </SideBarStyledOuter>
      )}

      <ContentStyled>
        <Content>
          <ContentHeader>
            <Name>Mateus</Name><Surname>Nobre</Surname>

          </ContentHeader>
          <ContentBody>
            {/* Conteúdo condicional baseado na seção selecionada */}
            {selectedSection === 'About' && (
              <>
                <h3>Full Stack Developer and Programming Teacher</h3>
                <p>Hello, my name is Mateus Nobre and I'm a Full Stack developer with a passion for both software development and teaching programming.</p>
              </>
            )}
            {selectedSection === 'Education' && (
              <>
                <h3>Experience Section</h3>
                <p>Content for Experience section goes here.</p>
              </>
            )}
          </ContentBody>
          <ContentButton>
            <Button texto='Ir para o site' color="inherit" onClick={goToHome} />
          </ContentButton>

        </Content>
      </ContentStyled>
    </ContainerStyled >
  );
};

export default Welcome;