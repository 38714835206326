import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { useNavigate } from 'react-router-dom';


import Button from '../../../Components/Button/ButtonMaterial';
import Sidebar from '../../../Components/SideBar/MiniDrawer';

function HtmlTelaLogin() {
    const navigate = useNavigate();

    const codigo = `
    <!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Tela de Login</title>
    <style>
        body {
            font-family: Arial, sans-serif;
            background-color: #f0f0f0;
        }

        .login-container {
            width: 300px;
            margin: 100px auto;
            padding: 20px;
            background-color: #fff;
            border-radius: 5px;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        }

        .form-group {
            margin-bottom: 20px;
        }

        .form-group label {
            display: block;
            margin-bottom: 5px;
        }

        .form-group input {
            width: calc(100% - 16px); /* Ajuste para compensar o padding */
            padding: 8px;
            border-radius: 3px;
            border: 1px solid #ccc;
            box-sizing: border-box; /* Para incluir o padding na largura total */
        }

        .form-group button {
            width: 100%;
            padding: 8px;
            border: none;
            border-radius: 3px;
            background-color: #007bff;
            color: #fff;
            cursor: pointer;
        }

        .form-group button:hover {
            background-color: #0056b3;
        }

        .error-message {
            color: red;
            margin-top: 10px;
        }
    </style>
</head>
<body>

<div class="login-container">
    <h2>Login</h2>
    <div class="form-group">
        <label for="username">Usuário:</label>
        <input type="text" id="username" placeholder="Digite seu usuário">
    </div>
    <div class="form-group">
        <label for="password">Senha:</label>
        <input type="password" id="password" placeholder="Digite sua senha">
    </div>
    <div class="form-group">
        <button onclick="login()">Entrar</button>
    </div>
    <p id="message" class="error-message"></p>
</div>

<script>
    function login() {
        var username = document.getElementById('username').value;
        var password = document.getElementById('password').value;
        var messageElement = document.getElementById('message');

        // Verifica se o usuário e a senha são válidos (neste exemplo, apenas verifica se estão preenchidos)
        if (username && password) {
            // Simula uma ação de login bem-sucedida
            messageElement.innerText = "Login bem-sucedido!";
            messageElement.classList.remove('error-message');
        } else {
            // Exibe uma mensagem de erro se o usuário ou a senha estiverem em branco
            messageElement.innerText = "Por favor, preencha todos os campos.";
            messageElement.classList.add('error-message');
        }
    }
</script>

</body>
</html>
  `;

    return (
        <div>
            <Sidebar>
                <h1>Tela de login</h1>

                <p>
                    Este exemplo mostra uma tela de login simples com campos de usuário e senha e um
                    botão "Entrar". Quando o botão é clicado, a função login() é chamada,
                    que verifica se os campos de usuário e senha estão preenchidos. Se ambos
                    estiverem preenchidos, uma mensagem de "Login bem-sucedido!" é exibida; caso
                    contrário, uma mensagem de erro é exibida.
                </p>

                <SyntaxHighlighter language="html" style={darcula}>
                    {codigo}
                </SyntaxHighlighter>


            </Sidebar>
        </div>
    );
}

export default HtmlTelaLogin;


