import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/prism';

import Sidebar from '../../../Components/SideBar/MiniDrawer';

function CssWidthHeight() {

    const css = `
    /* Estilizando um retângulo */
    .retangulo {
        width: 200px; /* Define a largura do retângulo como 200 pixels */
        height: 100px; /* Define a altura do retângulo como 100 pixels */
        background-color: blue; /* Define a cor de fundo como azul */
    };`;

    const html = `<div class="retangulo"></div>`;

    return (
        <div>
            <Sidebar>
                <h1>Width e Height</h1>

                <p>
                    Aqui está um exemplo simples de como você pode usar largura (width) e altura (height) em CSS para estilizar um elemento
                </p>

                <SyntaxHighlighter language="css" style={darcula}>
                    {css}
                </SyntaxHighlighter>
                <br />

                <p>Neste exemplo, estamos estilizando um elemento com a classe <b>.retangulo</b>. Definimos sua largura como 200 pixels e sua altura como 100 pixels. Além disso,
                    definimos a cor de fundo como azul. Você pode aplicar essa classe a qualquer elemento HTML para fazer com que ele tenha essas dimensões e estilo.
                    Por exemplo
                </p>


                <SyntaxHighlighter language="html" style={darcula}>
                    {html}
                </SyntaxHighlighter>

                <p>Isso criará um retângulo azul com 200 pixels de largura e 100 pixels de altura na página da web.</p>
            </Sidebar>
        </div>
    );
}

export default CssWidthHeight;



