import styled from "styled-components";
// <div >
//   <h1 className="text-3xl font-bold underline">
//     Hello Home Page
//   </h1>
// </div>

export const ContainerStyled = styled.div`

  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
  /* overflow: hidden; */
  @media (max-width: 876px) { /* Menor que 770px */
    background-color: white;
    flex-direction: column;
  }
    
`;
export const SideBarStyled = styled.div`
  background-color: rgb(189,93,56);
  /* width: 30%; */
  /* width: 17rem; */
  width: 272px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 876px) { /* Menor que 770px */
    background-color:  rgb(189,93,56);
    width: 100%;
    height: 272px;
  }
`;
export const SideBarStyledOuter = styled.div`
background-color:  rgb(189,93,56);
  width: 100%;
  height: 20%;
  display: flex;
    flex-direction: row;
    justify-content: space-around;
    /* align-content: center; */
    align-items: center;
  h1{
    margin: 0;
  }
`;
export const ContentStyled = styled.div`
  /* background-color: white; */
  /* width: 30%; */
  width: calc(100% - 272px);
  height: 100%;
  display: flex;
`;

export const ImageStyled = styled.img`
  width: 160px;
  height: 160px;
  border-radius: 100px;
  border: 10px solid rgb(202,125,96);
`;
export const H2Styled = styled.h2`
  color: white;
  font-weight: 800;
  letter-spacing: 0.05rem;
  text-transform: uppercase;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
`;

export const ContentHeader = styled.div`
  width: 100%;
  height: 42%;
  display: flex;
  flex-direction: row;
  /* background-color: yellow; */
  align-items: flex-end;
  @media (max-width: 602px) { /* Menor que 770px */
  flex-direction: column;
  align-items: flex-start;
  }
`;

export const Name = styled.h2`
  color: black;
  font-size: 91px;
  font-family:'Roboto', sans-serif;
  margin: 0;
`;

export const Surname = styled.h2`
  color: #BD5D38;
  font-size: 91px;
  font-family:'Roboto', sans-serif;
  margin: 0;
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-left: 3rem;
  padding-right: 3rem;
  padding-top: 5rem;
  padding-bottom: 5rem;
`;


export const ContentBody = styled.div`
  width: 100%;
  height: auto;
`;
export const ContentButton = styled.div`
  width: 100%;
  /* height: 43%; */
  height: auto;
  display: flex;
  flex-direction: row;
  /* background-color: #4b4b4a; */
  align-items: flex-start;
`;


export const Section1 = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-left: 3rem;
  padding-right: 3rem;
  padding-top: 5rem;
  padding-bottom: 5rem;
`;

export const Section2 = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-left: 3rem;
  padding-right: 3rem;
  padding-top: 5rem;
  padding-bottom: 5rem;
`;