import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { useNavigate } from 'react-router-dom';
import { ButtonContainer, } from './styles';

import Button from '../../../Components/Button/ButtonMaterial';
import Sidebar from '../../../Components/SideBar/MiniDrawer';

function ArquiteturaSistemasUmHome() {
    const navigate = useNavigate();


    function chamaOqueeHtml() {
        navigate('/oqueehtml');
    }
    function chamaOqueeCss() {
        navigate('/oqueecss');
    }


    function chamabotao() {
        console.log('chamabotao');
    }


    return (
        <div>
            <Sidebar>
                <h1>Seção HTML, aqui estão os códigos básicos em html</h1>

                <ButtonContainer>
                    {/* <Button texto='oi' /> */}

                    <Button texto='Arquitetura Limpa: o Guia do Artesão Para Estrutura e Design de Software' setMarginRight='5px' setMarginBottom='5px' onClick={chamabotao} />
                    
                    <Button texto='O que é a um sistema Operacional' onClick={chamaOqueeHtml} setMarginRight='5px' setMarginBottom='5px' />
                    <Button texto='O que é a arquitetura de Sistemas' onClick={chamaOqueeCss} setMarginRight='5px' setMarginBottom='5px' />


                    
                </ButtonContainer>
            </Sidebar>
        </div>
    );
}

export default ArquiteturaSistemasUmHome;


