import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { useNavigate } from 'react-router-dom';


import Button from '../../../Components/Button/ButtonMaterial';
import Sidebar from '../../../Components/SideBar/MiniDrawer';

function HtmlBotoes() {
    const navigate = useNavigate();

    const codigo = `
    <!DOCTYPE html>
    <html lang="en">
    <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Botão com Função JavaScript</title>
        <style>
            /* Estilos para o botão */
            .btn {
                padding: 10px 20px;
                text-align: center;
                text-decoration: none;
                display: inline-block;
                font-size: 16px;
                margin: 4px 2px;
                cursor: pointer;
                border-radius: 4px;
                background-color: #007bff;
                color: white;
                border: none;
            }
    
            .btn:hover {
                background-color: #0056b3;
            }
        </style>
    </head>
    <body>
    
    <!-- Botão que chama uma função JavaScript -->
    <button class="btn" onclick="exibirMensagem()">Clique Aqui</button>
    
    <script>
        // Função JavaScript que é chamada quando o botão é clicado
        function exibirMensagem() {
            console.log("Você clicou no botão!");
        }
    </script>
    
    </body>
    </html>
  `;



    return (
        <div>
            <Sidebar>
                <h1>Botões em HTML</h1>

                <SyntaxHighlighter language="html" style={darcula}>
                    {codigo}
                </SyntaxHighlighter>

             

            </Sidebar>
        </div>
    );
}

export default HtmlBotoes;


