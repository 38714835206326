import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { useNavigate } from 'react-router-dom';


import Button from '../../../Components/Button/ButtonMaterial';
import Sidebar from '../../../Components/SideBar/MiniDrawer';

function PhpFuncoesMatematicas() {
    const navigate = useNavigate();

    const codigo = `<?php
    // Exemplo usando ceil() - arredonda para cima
    $num1 = 5.3;
    $ceilResult = ceil($num1);
    echo "ceil(5.3) = $ceilResult <br>";
    
    // Exemplo usando floor() - arredonda para baixo
    $num2 = 5.7;
    $floorResult = floor($num2);
    echo "floor(5.7) = $floorResult <br>";
    
    // Exemplo usando round() - arredonda para o número inteiro mais próximo
    $num3 = 5.5;
    $roundResult = round($num3);
    echo "round(5.5) = $roundResult <br>";
    
    // Exemplo usando rand() - gera um número inteiro aleatório
    $randResult = rand(1, 100); // Gera um número aleatório entre 1 e 100
    echo "rand(1, 100) = $randResult <br>";
    
    // Exemplo usando sqrt() - calcula a raiz quadrada de um número
    $num4 = 25;
    $sqrtResult = sqrt($num4);
    echo "sqrt(25) = $sqrtResult <br>";`;

    return (
        <div>
            <Sidebar>
                <h1>Funções Matemáticas em PHP</h1>



                <p> Este código PHP demonstra o uso das funções matemáticas mencionadas:   </p>

                <p><b>ceil($num)</b> arredonda o número para cima para o inteiro mais próximo.</p>
                <p><b>floor($num)</b> arredonda o número para baixo para o inteiro mais próximo.</p>
                <p><b>round($num)</b> arredonda o número para o inteiro mais próximo, arredondando para cima ou para baixo conforme necessário.</p>
                <p><b>rand($min, $max)</b> gera um número inteiro aleatório entre $min e $max.</p>
                <p><b>sqrt($num)</b> calcula a raiz quadrada do número $num.</p>

                <SyntaxHighlighter language="php" style={darcula}>
                    {codigo}
                </SyntaxHighlighter>
     
            </Sidebar>
        </div>
    );
}

export default PhpFuncoesMatematicas;


