import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { useNavigate } from 'react-router-dom';


import Button from '../../../Components/Button/ButtonMaterial';
import Sidebar from '../../../Components/SideBar/MiniDrawer';

function PhpCondicoes() {
    const navigate = useNavigate();

    const codigo = `
    <?php
    // Definindo uma variável
    $idade = 20;
    
    // Verificando a idade e exibindo uma mensagem com base na condição
    if ($idade < 18) {
        echo "Você é menor de idade.";
    } else if ($idade >= 18 && $idade < 60) {
        echo "Você é um adulto.";
    } else {
        echo "Você é um idoso.";
    }
    ?>

  `;
    const codigoDois = `
  <?php
// Definindo duas variáveis
$temCartao = true;
$valorCompra = 100;

// Verificando a condição usando operadores lógicos
if ($temCartao || $valorCompra >= 100) {
    echo "Parabéns, você tem frete grátis!";
} else {
    echo "Você não tem direito ao frete grátis.";
}
?>`;


    return (
        <div>
            <Sidebar>
                <h1>Operadores condicionais em Php</h1>


                <p>
                    Em PHP, você pode usar estruturas de controle de fluxo, como instruções condicionais,
                    para executar código com base em determinadas condições. As instruções condicionais mais comuns em PHP
                    são <b>if</b>, <b>else if</b> (também conhecido como <b>elseif</b>), e <b>else</b>.
                </p>

                Neste exemplo:

                <p>A variável <b>$idade</b> é definida como 20.</p>
                <p>A condição <b>if</b> verifica se a idade é menor que 18, se for verdadeira, exibe a mensagem <b>"Você é menor de idade."</b>.</p>
                <p>A condição <b>else if</b> verifica se a idade é maior ou igual a 18 e menor que 60, se for verdadeira, exibe a mensagem <b>"Você é um adulto."</b>.</p>
                <p>Se nenhuma das condições anteriores for verdadeira, a instrução <b>else</b> é executada e exibe a mensagem <b>"Você é um idoso."</b>.</p>


                <SyntaxHighlighter language="php" style={darcula}>
                    {codigo}
                </SyntaxHighlighter>

                <h3>Você também pode usar operadores lógicos, como && (AND), || (OR), e ! (NOT), para criar condições mais complexas em PHP. Por exemplo:</h3>


                <SyntaxHighlighter language="php" style={darcula}>
                    {codigoDois}
                </SyntaxHighlighter>

                <p>
                    Neste exemplo, a condição verifica se o cliente tem um cartão de fidelidade OU se o valor da compra é maior ou igual a <b>100</b>.
                    Se uma das condições for verdadeira, o cliente tem direito ao frete grátis.
                </p>
            </Sidebar>
        </div>
    );
}

export default PhpCondicoes;


