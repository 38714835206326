import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { useNavigate } from 'react-router-dom';


import Button from '../../../Components/Button/ButtonMaterial';
import Sidebar from '../../../Components/SideBar/MiniDrawer';

function JsFuncoesMath() {

    const navigate = useNavigate();

    const trigonometricas = `
    var angulo = Math.PI / 4; // Ângulo de 45 graus em radianos

    var seno = Math.sin(angulo);
    console.log("Seno:", seno); // Saída: 0.7071 (aproximadamente)

    var cosseno = Math.cos(angulo);
    console.log("Cosseno:", cosseno); // Saída: 0.7071 (aproximadamente)

    var tangente = Math.tan(angulo);
    console.log("Tangente:", tangente); // Saída: 1 (aproximadamente)`;


    const Arredondamento = `
    var numero = 3.7;

    var arredondado = Math.round(numero);
    console.log("Arredondado:", arredondado); // Saída: 4
    
    var paraBaixo = Math.floor(numero);
    console.log("Para Baixo:", paraBaixo); // Saída: 3
    
    var paraCima = Math.ceil(numero);
    console.log("Para Cima:", paraCima); // Saída: 4`;

    const ExponenciacaoeLogaritmo = `
    var base = 2;
    var expoente = 3;
    
    var potencia = Math.pow(base, expoente);
    console.log("Potência:", potencia); // Saída: 8
    
    var exponencial = Math.exp(2); // e elevado à potência de 2
    console.log("Exponencial:", exponencial); // Saída: 7.389 (aproximadamente)
    
    var logaritmo = Math.log(10); // Logaritmo natural de 10
    console.log("Logaritmo:", logaritmo); // Saída: 2.303 (aproximadamente)`;

    const RaizeValorAbsoluto = `
    var numeroNegativo = -9;

    var valorAbsoluto = Math.abs(numeroNegativo);
    console.log("Valor Absoluto:", valorAbsoluto); // Saída: 9

    var raizQuadrada = Math.sqrt(25);
    console.log("Raiz Quadrada:", raizQuadrada); // Saída: 5`;

    const FlooreCeil = `
    var numero1 = Math.floor(3.9);
    console.log(numero1); // Saída: 3
    
    var numero2 = Math.ceil(3.1);
    console.log(numero2); // Saída: 4`;

    return (
        <div>
            <Sidebar>
                <h1>Funções Math</h1>

                <p>
                    As funções <b>Math</b> em JavaScript são um conjunto de funções embutidas na linguagem que permitem realizar operações matemáticas mais avançadas.
                    Essas funções são acessadas através do objeto <b>Math</b>, que contém métodos estáticos para diversas operações matemáticas comuns.
                </p>

                <p>O objeto <b>Math</b> fornece uma ampla gama de funções matemáticas que podem ser usadas para realizar cálculos complexos.
                    Aqui estão algumas categorias comuns de funções que você pode encontrar no objeto <b>Math</b>:
                </p>

                <ul>
                    <li><p><b>Funções Trigonométricas:</b> Como Math.sin, Math.cos, Math.tan, que calculam os valores das funções trigonométricas para um determinado ângulo (em radianos).</p></li>
                    <li><p><b>Funções de Arredondamento:</b> Como Math.round, Math.floor, Math.ceil, que permitem arredondar números para o valor mais próximo, para baixo ou para cima, respectivamente.</p></li>
                    <li><p><b>Funções de Exponenciação e Logaritmo:</b> Além de Math.pow para potenciação, há Math.exp para calcular o exponencial de um número e Math.log para calcular o logaritmo natural.</p></li>
                    <li><p><b>Funções de Raiz e Valor Absoluto: </b> Math.sqrt para calcular a raiz quadrada de um número e Math.abs para retornar o valor absoluto (magnitude) de um número.</p></li>
                    <li><p><b>Funções de Mínimo e Máximo: </b> Math.min e Math.max para encontrar o menor e o maior valor em um conjunto de números, respectivamente.</p></li>
                    <li><p><b>Constantes Matemáticas: </b>  O objeto Math também contém constantes matemáticas, como Math.PI para o valor de π (pi) e Math.E para o valor da base do logaritmo natural.</p></li>
                    <li><p><b>Math.floor e Math.ceil:</b> Math.floor arredonda um número para baixo até o inteiro mais próximo, enquanto Math.ceil arredonda um número para cima até o inteiro mais próximo.</p></li>
                </ul>


                <b>Funções Trigonométricas:</b>
                <SyntaxHighlighter language="javascript" style={darcula}>
                    {trigonometricas}
                </SyntaxHighlighter>

                <br />
                <b>Funções de Arredondamento:</b>
                <SyntaxHighlighter language="javascript" style={darcula}>
                    {Arredondamento}
                </SyntaxHighlighter>

                <br />
                <b>Funções de Exponenciação e Logaritmo:</b>
                <SyntaxHighlighter language="javascript" style={darcula}>
                    {ExponenciacaoeLogaritmo}
                </SyntaxHighlighter>

                <br />
                <b>Funções de Raiz e Valor Absoluto:</b>
                <SyntaxHighlighter language="javascript" style={darcula}>
                    {RaizeValorAbsoluto}
                </SyntaxHighlighter>

                <br />
                <b>Math.floor e Math.ceil: Math.floor:</b>
                <SyntaxHighlighter language="javascript" style={darcula}>
                    {FlooreCeil}
                </SyntaxHighlighter>
            </Sidebar>
        </div>
    );
}

export default JsFuncoesMath;



