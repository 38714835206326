import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { useNavigate } from 'react-router-dom';


import Button from '../../../Components/Button/ButtonMaterial';
import Sidebar from '../../../Components/SideBar/MiniDrawer';

function HtmleJavascript() {
    const navigate = useNavigate();

    const codigo = `
    <!DOCTYPE html>
    <html lang="en">
    <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Exemplo de JavaScript em HTML</title>
    </head>
    <body>
        <h1>Exemplo de JavaScript em HTML</h1>
        
        <script>
            // Seu código JavaScript aqui
            alert("Olá, mundo!");
        </script>
    </body>
    </html>
  `;

    const codigoSeparado = `
  <!DOCTYPE html>
  <html lang="en">
  <head>
      <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <title>Exemplo de JavaScript em HTML</title>
  </head>
  <body>
      <h1>Exemplo de JavaScript em HTML</h1>
      
      <script src="meu_script.js"></script>
  </body>
  </html>
  `;

    const arquivojs = `
  // meu_script.js
  alert("Olá, mundo!");
  `;

    return (
        <div>
            <Sidebar>
                <h1>Integrando o Javascript no Html</h1>

                <h3>Para integrar JavaScript em um arquivo HTML, você pode usar a tag <b>script</b>. Existem algumas maneiras de fazer isso:</h3>
                <p>
                    <b>Incorporar JavaScript diretamente no HTML:</b>
                    Você pode adicionar o código JavaScript diretamente dentro da tag <b>script</b> no arquivo HTML. Por exemplo:
                </p>
                <SyntaxHighlighter language="html" style={darcula}>
                    {codigo}
                </SyntaxHighlighter>

                <p>
                    <b>Incluir um arquivo JavaScript externo:</b>
                    Você também pode criar um arquivo JavaScript separado e incluí-lo no seu HTML usando a tag <b>script</b> com o atributo src. Por exemplo:

                    No arquivo HTML (index.html):
                </p>

                <SyntaxHighlighter language="html" style={darcula}>
                    {codigoSeparado}
                </SyntaxHighlighter>
                <p>
                    E no arquivo JavaScript (meu_script.js):
                </p>
                <SyntaxHighlighter language="javascript" style={darcula}>
                    {arquivojs}
                </SyntaxHighlighter>


            </Sidebar>
        </div>
    );
}

export default HtmleJavascript;


