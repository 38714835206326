import styled from "styled-components";
// <div >
//   <h1 className="text-3xl font-bold underline">
//     Hello Home Page
//   </h1>
// </div>

export const ContainerStyled = styled.div`
    background-color: #f0e9e9;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;
export const ButtonStyled = styled.button`
    width: 350px;
    height: 200px;
    font-size: 50px;
    border: 2px solid black;
    border-radius: 20px;
    transition: background-color 0.3s ease; /* Adicionando a transição */

    &:hover {
        background-color: orange;
    }
`;