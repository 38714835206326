import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { useNavigate } from 'react-router-dom';


import Button from '../../../Components/Button/ButtonMaterial';
import Sidebar from '../../../Components/SideBar/MiniDrawer';

function HtmlSeletoresAtributos() {
    const navigate = useNavigate();

    const codigo = `<div id="meu-id-unico">Conteúdo do elemento</div>`;

    const codigocss = `#meu-id-unico {
        background-color: blue;
        color: white;
    }
    
    .classe {
        font-size: 16px;
    }`;

    return (
        <div>
            <Sidebar>
                <h1>Seletores e atributos</h1>

                <p>Em HTML e CSS, os seletores e atributos ID são usados para identificar elementos específicos em uma página da web. Aqui está uma explicação mais detalhada sobre cada um:</p>


                <h3>Seletores:</h3>
                <p>Os seletores em CSS são padrões que são usados para selecionar os elementos HTML aos quais você deseja aplicar estilos. Existem vários tipos de seletores, mas os mais comuns incluem:</p>

                <ul>
                    <li><b>Seletores de tipo:</b> Selecionam elementos com base em seus tipos. Por exemplo, <b>p</b> seleciona todos os parágrafos <b>{'<p>'}</b>.</li>
                    <li><b>Seletores de classe:</b> Selecionam elementos com base em suas classes. Por exemplo, .classe seleciona todos os elementos com a classe <b>classe</b>.</li>
                    <li><b>Seletores de ID:</b> Selecionam elementos com base em seus IDs exclusivos. Por exemplo, #id seleciona um elemento com o ID <b>id</b>.</li>
                    <li><b>Seletores de atributo:</b> Selecionam elementos com base em seus atributos. Por exemplo, <b>[type="text"] </b>seleciona todos os elementos com o atributo <b>type</b> igual a <b>"text"</b>.</li>
                </ul>

                <h3>Atributos ID:</h3>
                <p>O atributo ID em HTML é usado para identificar exclusivamente um elemento na página. Ele deve ser único dentro do documento HTML. Aqui está um exemplo de como um ID é usado em um elemento HTML:</p>
                <SyntaxHighlighter language="html" style={darcula}>
                    {codigo}
                </SyntaxHighlighter>
                <p>Neste exemplo, o elemento {'<div>'} possui o atributo <b>id</b> definido como <b>"meu-id-unico"</b>. Isso significa que você pode selecionar esse elemento exclusivamente usando o seletor
                    <b>#meu-id-unico</b> em CSS ou manipular o elemento usando JavaScript.
                </p>


                <h3>Exemplo de uso em CSS:</h3>
                <p>Neste exemplo, o seletor <b>#meu-id-unico</b> aplica um estilo específico ao elemento com o ID <b>"meu-id-unico"</b>, enquanto o seletor <b>.classe</b> aplica
                    um estilo a todos os elementos com a classe <b>classe</b>.
                </p>
                <SyntaxHighlighter language="css" style={darcula}>
                    {codigocss}
                </SyntaxHighlighter>

            </Sidebar>
        </div>
    );
}

export default HtmlSeletoresAtributos;


