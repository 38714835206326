import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { useNavigate } from 'react-router-dom';


import Button from '../../../Components/Button/ButtonMaterial';
import Sidebar from '../../../Components/SideBar/MiniDrawer';

function JsOperacoesMatematicas() {

    const navigate = useNavigate();

    const operacoesMatematicas = `
    // Adição
    var soma = 2 + 3;
    console.log(soma); // Saída: 5
    
    // Subtração
    var diferenca = 5 - 3;
    console.log(diferenca); // Saída: 2
    
    // Multiplicação
    var produto = 2 * 3;
    console.log(produto); // Saída: 6
    
    // Divisão
    var quociente = 6 / 2;
    console.log(quociente); // Saída: 3
    
    // Módulo (resto da divisão)
    var resto = 7 % 3;
    console.log(resto); // Saída: 1`;



    return (
        <div>
            <Sidebar>
                <h1>Operações matemáticas</h1>

                <p>Você pode fazer operações matemáticas com javascript, aqui alguns exemplos.
                </p>


                <SyntaxHighlighter language="javascript" style={darcula}>
                    {operacoesMatematicas}
                </SyntaxHighlighter>

            </Sidebar>
        </div>
    );
}

export default JsOperacoesMatematicas;


