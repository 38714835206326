import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { useNavigate } from 'react-router-dom';
import { ButtonContainer, } from './styles';

import Button from '../../../Components/Button/ButtonMaterial';
import Sidebar from '../../../Components/SideBar/MiniDrawer';

function CssHome() {
    const navigate = useNavigate();


    function chamaClassePseudoClasse() {
        navigate('/cssclasspseudoclass');
    }

    function chamaCssWidthHeight() {
        navigate('/csswidthheight');
    }
    function chamaCssCorBackgroundCor() {
        navigate('/csscorbackgroundcor');
    }
    function chamaCssHover() {
        navigate('/csshover');
    }
 

    function chamabotao() {
        console.log('chamabotao');
    }


    return (
        <div>
            <Sidebar>
                <h1>Seção CSS, aqui estão os códigos básicos em css</h1>

                <ButtonContainer>
                    {/* <Button texto='oi' /> */}
                    
                    <Button texto='Classe e Pseudo-class' onClick={chamaClassePseudoClasse} setMarginRight='5px' setMarginBottom='5px' />
                    <Button texto='Width Height' onClick={chamaCssWidthHeight} setMarginRight='5px' setMarginBottom='5px' />
                    <Button texto='Color e background color' onClick={chamaCssCorBackgroundCor} setMarginRight='5px' setMarginBottom='5px' />
                    <Button texto='Hover' onClick={chamaCssHover} setMarginRight='5px' setMarginBottom='5px' />
                   
                    
                </ButtonContainer>
            </Sidebar>
        </div>
    );
}

export default CssHome;


