import styled from "styled-components";
// <div >
//   <h1 className="text-3xl font-bold underline">
//     Hello Home Page
//   </h1>
// </div>



export const TableContainer = styled.div`
    background-color: #f7f2f2;
    width: 100%;
    height: 100%;
`;

export const ThStyled = styled.th`
    background-color: #f7f2f2;
    width: 30%;
    height: 55px;
  
`;  
export const TdStyled = styled.td`
    text-align: center;
    height: 35px;
    // background-color: red;
`; 