import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { useNavigate } from 'react-router-dom';


import Button from '../../../Components/Button/ButtonMaterial';
import Sidebar from '../../../Components/SideBar/MiniDrawer';

function SqlXampp() {
    const navigate = useNavigate();


    return (
        <div>
            <Sidebar>
                <h1>XAMPP </h1>

                <div>
                    O XAMPP é um pacote de software gratuito e de código aberto que simplifica a configuração de um ambiente de desenvolvimento web local.
                    O nome XAMPP é um acrônimo que representa os principais componentes do pacote:
                </div>
                <div>
                    <ul>
                        <li><p><b>X: </b>refere-se a qualquer sistema operacional, como Windows, Linux ou macOS.</p></li>

                        <li><p><b>A: </b>Apache, um servidor web amplamente utilizado.</p></li>

                        <li><p><b>M: </b>MySQL, um sistema de gerenciamento de banco de dados relacional.</p></li>

                        <li><p><b>P: </b>PHP, uma linguagem de programação popular para desenvolvimento web.</p></li>

                        <li><p><b>P:</b>  Perl, uma linguagem de script utilizada em desenvolvimento web.</p></li>
                    </ul>
                </div>
                <div>
                    O XAMPP é frequentemente utilizado por desenvolvedores para criar e testar sites e aplicativos web em seus computadores locais antes de implantá-los em servidores de produção.
                    Ele fornece uma maneira fácil de instalar e configurar esses componentes essenciais, permitindo que os desenvolvedores tenham um ambiente de desenvolvimento funcional rapidamente,
                    sem a necessidade de configurar cada componente separadamente.
                </div>

            </Sidebar>
        </div>
    );
}

export default SqlXampp;


