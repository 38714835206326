import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { useNavigate } from 'react-router-dom';


import Button from '../../../Components/Button/ButtonMaterial';
import Sidebar from '../../../Components/SideBar/MiniDrawer';

function ProvasTerceiroAno() {
    const navigate = useNavigate();

    const handleDownloadDesenvolvimentoAplicativos = () => {
        const pdfUrl = './DESENVOLVIMENTO_DE_APLICATIVOS.pdf';  // Substitua pelo caminho correto do seu PDF
        const link = document.createElement('a');
        link.href = pdfUrl;
        link.download = 'DESENVOLVIMENTO_DE_APLICATIVOS.pdf';
        link.click();
    };

    const handleDownloadBackEnd = () => {
        const pdfUrl = './BACK_END.pdf';  // Substitua pelo caminho correto do seu PDF
        const link = document.createElement('a');
        link.href = pdfUrl;
        link.download = 'BACK_END.pdf';
        link.click();
    };

    const handleDownloadArquiteturaSistemas = () => {
        const pdfUrl = './Analise_e_projetos_de_sistemas.pdf';  // Substitua pelo caminho correto do seu PDF
        const link = document.createElement('a');
        link.href = pdfUrl;
        link.download = 'Análise_e_projetos_de_sistemas.pdf';
        link.click();
    };


    return (
        <div>
            <Sidebar>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} >

                    <h1>Desenvolvimento de Aplicativos</h1>
                    <Button texto='Baixar PDF' onClick={handleDownloadDesenvolvimentoAplicativos} setMarginRight='5px' />


                    <h1>Empreendedorismo</h1>

                    <Button texto='Baixar PDF' onClick={handleDownloadArquiteturaSistemas} setMarginRight='5px' />

                    <h1>Back-End</h1>
                    <Button texto='Baixar PDF' onClick={handleDownloadBackEnd} setMarginRight='5px' />
                </div>
            </Sidebar>
        </div>
    );
}

export default ProvasTerceiroAno;


