import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { useNavigate } from 'react-router-dom';


import Button from '../../../Components/Button/ButtonMaterial';
import Sidebar from '../../../Components/SideBar/MiniDrawer';

function HtmleCss() {
    const navigate = useNavigate();

    const codigo = `
    <!DOCTYPE html>
    <html lang="en">
    <head>
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link rel="stylesheet" href="styles.css">
        <title>Document</title>
    </head>
    <body>
        <p>Este é um parágrafo com estilo definido no arquivo externo.</p>
        <p class="destaque">Este parágrafo está em destaque.</p>
    </body>
    </html>
  `;
    const codigoCss = `
  /* Estilo para todos os parágrafos */
  p {
      color: blue;
      font-size: 16px;
  }
  
  /* Estilo para elementos com a classe "destaque" */
  .destaque {
      background-color: yellow;
      font-weight: bold;
  }
`;

    const codigoStyles = `
<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Exemplo de CSS</title>
    <style>
        /* Estilo para todos os parágrafos */
        p {
            color: blue;
            font-size: 16px;
        }

        /* Estilo para elementos com a classe "destaque" */
        .destaque {
            background-color: yellow;
            font-weight: bold;
        }
    </style>
</head>
<body>
    <p>Este é um parágrafo com estilo definido na tag style.</p>
    <p class="destaque">Este parágrafo está em destaque.</p>
</body>
</html>
`;

const codigoCssInline = `
<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Exemplo de CSS</title>
</head>
<body>
    <p style="color: blue; font-size: 16px;">Este é um parágrafo com estilo inline.</p>
    <p class="destaque" style="background-color: yellow; font-weight: bold;">Este parágrafo está em destaque.</p>
</body>
</html>
`;

    return (
        <div>
            <Sidebar>
                <h1>Integrando o Css no Html</h1>

                <SyntaxHighlighter language="html" style={darcula}>
                    {codigo}
                </SyntaxHighlighter>

                <h1>Agora vamos adicionar umas classes no CSS</h1>

                <SyntaxHighlighter language="css" style={darcula}>
                    {codigoCss}
                </SyntaxHighlighter>
                <div>


                </div>
                <h1>Mas existem outras formas, uma delas é adicionar a tag Styles</h1>
                <SyntaxHighlighter language="html" style={darcula}>
                    {codigoStyles}
                </SyntaxHighlighter>

                <h1>E outra é chamada de css inline</h1>
                <SyntaxHighlighter language="html" style={darcula}>
                    {codigoCssInline}
                </SyntaxHighlighter>
            </Sidebar>
        </div>
    );
}

export default HtmleCss;


