import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/prism';

import Sidebar from '../../../Components/SideBar/MiniDrawer';

function CssHover() {

    const css = `
    /* Estilizando a div "caixa" */
    .caixa {
        width: 200px;
        height: 200px;
        background-color: blue;
    }
    
    /* Estilizando a div "caixa" quando o mouse passa sobre ela */
    .caixa:hover {
        width: 200px;
        height: 200px;
        background-color: red; /* Altera a cor de fundo para vermelho quando o mouse passa sobre ela */
    }`;

    const html = ` <div class="caixa"></div>`;

    return (
        <div>
            <Sidebar>
                <h1>Hover</h1>

                <p>
                    Você pode usar a pseudo-classe <b>:hover</b> em CSS para aplicar estilos quando o mouse passa sobre um elemento. Aqui está um exemplo de como você pode fazer isso com uma div chamada "caixa":
                </p>

                <SyntaxHighlighter language="css" style={darcula}>
                    {css}
                </SyntaxHighlighter>
                <br />

                <p>Neste exemplo, a div com a classe <b>.caixa</b> terá inicialmente um fundo azul. Quando o mouse passar sobre essa div,
                    a cor de fundo mudará para vermelho, graças à regra definida em <b>.caixa:hover</b>.
                </p>


                <SyntaxHighlighter language="html" style={darcula}>
                    {html}
                </SyntaxHighlighter>

                <p>Com isso, você terá uma div chamada "caixa" que muda de cor quando o mouse passa sobre ela.</p>
            </Sidebar>
        </div>
    );
}

export default CssHover;



