import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { useNavigate } from 'react-router-dom';

import { ContainerStyled, } from './styles';


import Button from '../../Components/Button/ButtonMaterial';

import Sidebar from '../../Components/SideBar/MiniDrawer';

function Home() {
    const navigate = useNavigate();


    function chamabotao() {
        console.log('oi');
    }
    function chamaHomePhp() {
        navigate('/homephp');
    }
    function chamaHomeHtml() {
        navigate('/homehtml');
    }
    function chamaHomeCss() {
        navigate('/homecss');
    }
    function chamaProgramacaoOrientadaObjetos() {
        navigate('/programacaoorientadaobjetos');
    }
    function chamaHomeJavascript() {
        navigate('/homejavascript');
    }
    function chamaHomeSql() {
        navigate('/homesql');
    }
    function chamaArquiteturaSistemasUm() {
        navigate('/arquiteturasistemasum');
    }
    function chamaEstudosProvas() {
        navigate('/estudosprovas');
    }

    function chamaColegioPlano() {
        navigate('/colegioplano');
    }
    


    return (
        <div>
            <Sidebar>
                <h1>Conteúdo</h1>

                <ContainerStyled>
                    {/* <Button texto='oi' /> */}
                    <Button texto='HTML e CSS' onClick={chamaHomeHtml} setMarginRight='5px' />
                    <Button texto='CSS' onClick={chamaHomeCss} setMarginRight='5px' />
                    <Button texto='Javascript' onClick={chamaHomeJavascript} setMarginRight='5px' />

                    <Button texto='Php' onClick={chamaHomePhp} setMarginRight='5px' />
                    <Button texto='MySQL' onClick={chamaHomeSql} setMarginRight='5px' />

                    <Button texto='Programação Orientada a Objetos' onClick={chamaProgramacaoOrientadaObjetos} setMarginRight='5px' />

                    <Button texto='Arquitetura de sistemas 1' onClick={chamaArquiteturaSistemasUm} setMarginRight='5px' />

                    
                    <Button texto='Estudos para as provas' onClick={chamaEstudosProvas} setMarginRight='5px' />

                    <Button texto='Colégio Plano' onClick={chamaColegioPlano} setMarginRight='5px' />

                </ContainerStyled>
            </Sidebar>
        </div>
    );
}

export default Home;



// import React from 'react';
// import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
// import { darcula } from 'react-syntax-highlighter/dist/esm/styles/prism';

// import Sidebar from '../../Components/SideBar/MiniDrawer';

// function Home() {

//     const codigo = `
//     <!DOCTYPE html>
//     <html lang="en">
//     <head>
//         <meta charset="UTF-8">
//         <meta name="viewport" content="width=device-width, initial-scale=1.0">
//         <title>Document</title>
//     </head>
//     <body>
//         <p>Oi</p>
//     </body>
//     </html>
// `;
//     return (
//         <div>
//             <Sidebar>
//                 <h1>oi</h1>

//                 <div ></div>
//                 <SyntaxHighlighter language="javascript" style={darcula}>
//                     {codigo}
//                 </SyntaxHighlighter>
//             </Sidebar>

//         </div>
//     );
// }

// export default Home;
