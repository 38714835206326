import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { useNavigate } from 'react-router-dom';


import Button from '../../../Components/Button/ButtonMaterial';
import Sidebar from '../../../Components/SideBar/MiniDrawer';

function OqueePhp() {
    const navigate = useNavigate();

    const codigo = `
    <!DOCTYPE html>
    <html>
    <head>
        <title>Exemplo PHP</title>
    </head>
    <body>
    
    <?php
    // Definindo variáveis
    $nome = "João";
    $idade = 30;
    
    // Exibindo os valores das variáveis
    echo "<h1>Olá, $nome!</h1>";
    echo "<p>Você tem $idade anos de idade.</p>";
    ?>
    
    </body>
    </html>
  `;

    return (
        <div>
            <Sidebar>
                <h1>O que é PHP?</h1>


                <div>
                    <p>
                        <b>PHP (Hypertext Preprocessor)</b> é uma linguagem de script do lado do servidor amplamente utilizada para desenvolvimento web. Ela foi originalmente criada por Rasmus Lerdorf em 1994 e desde então tem sido constantemente atualizada e expandida pela comunidade de desenvolvedores.
                    </p>

                    <p><b>Facilidade de aprendizado e uso:</b> PHP é conhecido por ser fácil de aprender e usar, especialmente para iniciantes em desenvolvimento web.</p>

                    <p><b>Compatibilidade:</b> PHP é compatível com várias plataformas, incluindo Windows, macOS e Linux, e funciona bem com a maioria dos servidores web, como <b>Apache</b> e <b>Nginx</b>.</p>

                    <p><b>Grande comunidade e recursos:</b> PHP tem uma vasta comunidade de desenvolvedores em todo o mundo, o que significa que há muitos recursos, tutoriais e bibliotecas disponíveis para ajudar os desenvolvedores a resolverem problemas e construírem projetos.</p>

                    <p><b>Integração com HTML:</b> PHP pode ser facilmente integrado com HTML, permitindo que os desenvolvedores criem páginas web dinâmicas e interativas.</p>

                    <p>Lembrando que o nome do arquivo é <b>index.php</b></p>

                    <h3>Como funciona o PHP?</h3>

                    <p><b>Execução do lado do servidor:</b> PHP é uma linguagem de script do lado do servidor, o que significa que o código PHP é executado no servidor web antes que o resultado seja enviado para o navegador do usuário. Isso permite que o PHP interaja com bancos de dados, processe formulários e gere conteúdo dinâmico antes que a página seja exibida para o usuário.</p>

                    <p><b>Incorporação em páginas HTML:</b> O código PHP pode ser incorporado em arquivos HTML usando tags especiais <b>{'<?php ?>'}</b>. Dentro dessas tags, você pode escrever código PHP que será executado pelo servidor.</p>
                </div>
                <SyntaxHighlighter language="php" style={darcula}>
                    {codigo}
                </SyntaxHighlighter>
            </Sidebar>
        </div>
    );
}

export default OqueePhp;


