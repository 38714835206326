import React, { useState, useEffect } from 'react';

import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { useNavigate } from 'react-router-dom';
import { ButtonContainer, } from './styles';
import { axiosBuscarDados, MyOwnAxiosInterface } from '../../Components/Axios/AxiosRequests';

import fs from 'fs';
import { objJsonDignidade } from './dignidade';
import { objJsonMoralidade } from './moralidade';
import { objJsonGratidao } from './gratidao';

import Button from '../../Components/Button/ButtonMaterial';
import Sidebar from '../../Components/SideBar/MiniDrawer';
import TabelaDeNotas from '../../Components/TabelaDeNotas/TabelaDeNotas';
import BasicSelect from '../../Components/SelectInput/SelectInput';
import { TextField } from '@mui/material';

interface Aluno {
  nome: string;
  total: number;
  nota: number;
}

interface AlunoArchive {
  nome: string;
  senha: string;
  disciplinas: Disciplina[];
}

interface Disciplina {
  materia: string;
  tipo: string;
  nota: number;
}


function NotaseTrabalhos() {
  const navigate = useNavigate();
  const [alunos, setAlunos] = useState<Aluno[]>([]);
  const [nome, setNome] = useState<string>('');
  const [senha, setSenha] = useState<string>('');
  const [selectSala, setSelectSala] = useState<string>('');

  useEffect(() => {
    const dadosDosAlunos: Aluno[] = [
      { nome: 'Solaire of Astora', total: 8, nota: 7 },
      { nome: 'Andre of Astora', total: 8, nota: 8 },
      { nome: 'Knight Lautrec of Carim', total: 8, nota: 3 },
      { nome: 'Siegmeyer of Catarina', total: 8, nota: 0.8 },
      { nome: 'Shiva of the East', total: 8, nota: 7.2 },
      { nome: 'Darkstalker Kaathe', total: 8, nota: 3.5 },
      { nome: 'Crestfallen Warrior', total: 8, nota: 6 },
      { nome: 'Patches the Hyena', total: 8, nota: 3 },
      { nome: 'Marvelous Chester', total: 8, nota: 5.8 },
      { nome: 'Laurentius of the Great Swamp', total: 8, nota: 2 },
      { nome: 'Eingyi of the Great Swamp', total: 8, nota: 5 },
      { nome: 'Blacksmith Rickert of Vinheim', total: 8, nota: 0 },
      { nome: 'Big Hat Logan', total: 8, nota: 1.5 },
    ];
    setAlunos(dadosDosAlunos);
  }, []);

  

  const searchForData = async () => {

    let notasE = {};
    if (selectSala === 'dignidade') {
      const alunos = objJsonDignidade.alunos;
      for (const aluno of alunos) {
        if (aluno.nome === nome && aluno.senha === senha) {
          notasE = aluno;
        }
      }
      console.log('notasE: ', notasE);

    } else if (selectSala === 'moralidade') {
      const alunos = objJsonMoralidade.alunos;
      for (const aluno of alunos) {
        if (aluno.nome === nome && aluno.senha === senha) {
          notasE = aluno;
        }
      }
      console.log('notasE: ', notasE);

    }
    else if (selectSala === 'gratidao') {
      const alunos = objJsonGratidao.alunos;
      for (const aluno of alunos) {
        if (aluno.nome === nome && aluno.senha === senha) {
          notasE = aluno;
        }
      }
      console.log('notasE: ', notasE);

    }
  }




  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    console.log('dentro da função');
    event.preventDefault(); // Evita o comportamento padrão de recarregar a página
    // Aqui você pode fazer o que quiser com os dados do formulário
    // Por exemplo, você pode chamar a função searchForData com a opção selecionada
    let count = 0;
    if (selectSala === '' || selectSala === null || selectSala === undefined) {
      alert('Escolha uma sala');
      count = count + 1;
    }
    if (nome === '' || nome === null || nome === undefined) {
      alert('Escolha um nome');
      count = count + 1;
    }
    if (senha === '' || senha === null || senha === undefined) {
      alert('Escolha uma senha');
      count = count + 1;
    }
    if (count <= 0) {
      searchForData();

    }
    console.log('selectSala: ', selectSala);
    console.log('nome: ', nome);
    console.log('senha: ', senha);
  }
  const handleNomeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNome(event.target.value);
  };

  const handleSenhaChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSenha(event.target.value);
  };

  return (
    <div>
      <Sidebar>
        <h1>Notas e Trabalhos</h1>
        <br />

        <form onSubmit={handleSubmit}>
          <BasicSelect onSelectChange={setSelectSala} />
          <TextField
            id="outlined-basic-nome"
            label="Nome"
            variant="outlined"
            value={nome}
            onChange={handleNomeChange}
          />
          <TextField
            id="outlined-basic-senha"
            label="Senha"
            variant="outlined"
            value={senha}
            onChange={handleSenhaChange}
          />
          <Button texto='Enviar' type="submit" color="primary" />
        </form>

        <div>
          <TabelaDeNotas data={alunos} />
        </div>

      </Sidebar>
    </div >
  );
}

export default NotaseTrabalhos;


