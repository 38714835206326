import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { useNavigate } from 'react-router-dom';


import Button from '../../../Components/Button/ButtonMaterial';
import Sidebar from '../../../Components/SideBar/MiniDrawer';

function BasicoHtml() {
    const navigate = useNavigate();

    const codigo = `
    <!DOCTYPE html>
    <html lang="en">
    <head>
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>Document</title>
    </head>
    <body>
        <p>Oi</p>
    </body>
    </html>
  `;

    return (
        <div>
            <Sidebar>
                <h1>Básico de Html</h1>

                <SyntaxHighlighter language="html" style={darcula}>
                    {codigo}
                </SyntaxHighlighter>
                <div>
                    <p>
                        <b> !DOCTYPE html:</b> Esta declaração define o tipo de documento como HTML5. Ela informa ao navegador qual versão do HTML está sendo usada e ajuda a garantir que a página seja renderizada corretamente.
                    </p>

                    <p>
                        <b>html lang="en": </b> Esta é a tag raiz que envolve todo o conteúdo HTML da página. O atributo lang especifica o idioma principal da página, que neste caso é inglês (en).
                    </p>

                    <p>
                        <b>head:</b> Esta é a seção do documento onde são colocadas informações sobre o documento, como metadados e links para arquivos externos. O conteúdo dentro da tag  <b>head</b> não é exibido na página, mas fornece informações importantes para o navegador e mecanismos de busca.
                    </p>

                    <p>
                        <b>meta charset="UTF-8":</b>Esta tag especifica o conjunto de caracteres utilizado no documento. O atributo charset define o conjunto de caracteres como UTF-8, que é amplamente utilizado e suporta uma ampla gama de caracteres, útil para conteúdo multilíngue.
                    </p>

                    <p>
                        <b>meta name="viewport" content="width=device-width, initial-scale=1.0": </b> Este meta tag controla a largura da viewport e a escala inicial da página em dispositivos móveis. Define que a largura da viewport deve ser igual à largura do dispositivo e a escala inicial deve ser 1.0.
                    </p>

                    <p>
                        <b>title Document title:</b> Esta tag define o título da página, que é exibido na barra de título do navegador ou na guia do navegador. É uma parte importante da otimização de mecanismos de busca (SEO) e deve ser descritiva e relevante para o conteúdo da página.
                    </p>

                    <p>
                        <b>body</b>: Esta é a seção do documento onde o conteúdo real da página é colocado, como texto, imagens, links e outros elementos HTML. Tudo o que é colocado dentro da tag <b>body</b> é exibido na página da web quando ela é renderizada no navegador.
                    </p>


                </div>

            </Sidebar>
        </div>
    );
}

export default BasicoHtml;


