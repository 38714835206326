
import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

interface BasicSelectProps {
    onSelectChange: (option: string) => void; // Adicionando a prop para a função de atualização
}

export default function BasicSelect({ onSelectChange }: BasicSelectProps) {
    const [sala, setSala] = React.useState('');

    const handleChange = (event: SelectChangeEvent) => {
        const selectedOption = event.target.value as string;
        setSala(selectedOption);
        onSelectChange(selectedOption); // Chamando a função de atualização com a opção selecionada
    };

    return (
        <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Sala</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={sala}
                    label="Sala"
                    onChange={handleChange}
                >
                    <MenuItem value={'dignidade'}>Dignidade</MenuItem>
                    <MenuItem value={'gratidao'}>Gratidão</MenuItem>
                    <MenuItem value={'moralidade'}>Moralidade</MenuItem>
                </Select>
            </FormControl>
        </Box>
    );
}