import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { useNavigate } from 'react-router-dom';


import Button from '../../../Components/Button/ButtonMaterial';
import Sidebar from '../../../Components/SideBar/MiniDrawer';

function JsLocalSessionStorage() {

    const navigate = useNavigate();

    const local = `
// Armazenando dados no localStorage
localStorage.setItem('nome', 'João');

// Recuperando dados do localStorage
var nome = localStorage.getItem('nome');
console.log(nome); // Saída: João

// Removendo um item do localStorage
localStorage.removeItem('nome');

// Limpando todos os dados do localStorage
localStorage.clear();`;

    const session = `
// Armazenando dados no sessionStorage
sessionStorage.setItem('nome', 'João');

// Recuperando dados do sessionStorage
var nome = sessionStorage.getItem('nome');
console.log(nome); // Saída: João

// Removendo um item do sessionStorage
// sessionStorage.removeItem('nome');

// Limpando todos os dados do sessionStorage
// sessionStorage.clear();`;


    return (
        <div>
            <Sidebar>
                <h1>localStorage e sessionStorage</h1>

                <p>O armazenamento em JavaScript refere-se à capacidade de armazenar dados localmente no navegador da web do usuário.
                    Existem duas formas principais de armazenamento: <b>localStorage</b> e <b>sessionStorage</b>.
                </p>

                <h3>localStorage</h3>
                <ul>
                    <li><p> O <b>localStorage </b>permite armazenar dados de forma persistente no navegador.</p></li>
                    <li><p> Os dados armazenados no <b>localStorage</b> permanecem mesmo depois que o navegador é fechado e são acessíveis em futuras sessões do navegador.</p></li>
                    <li><p> Os dados armazenados no <b>localStorage</b> têm um tempo de vida ilimitado, a menos que sejam explicitamente removidos pelo código ou pelo usuário.</p></li>
                    <li><p> Os dados no <b>localStorage</b> estão disponíveis em todas as guias e janelas do navegador que acessam o mesmo domínio.</p></li>
                    <li><p> Para usar o <b>localStorage</b>, você pode usar os métodos <b>setItem()</b>, <b>getItem()</b>, <b>removeItem()</b> e <b>clear()</b>.</p></li>
                </ul>

                <SyntaxHighlighter language="javascript" style={darcula}>
                    {local}
                </SyntaxHighlighter>
                <br />


                <h3>sessionStorage</h3>
                <ul>
                    <li><p> O <b>sessionStorage</b> é semelhante ao <b>localStorage</b>, mas os dados armazenados nele são destruídos quando a sessão do navegador termina, ou seja, quando o navegador é fechado.</p></li>
                    <li><p>Os dados armazenados no <b>sessionStorage</b> estão disponíveis apenas durante a sessão atual do navegador e não são compartilhados entre diferentes guias ou janelas do navegador.</p></li>
                    <li><p>O <b>sessionStorage</b> também usa os métodos <b>setItem()</b>, <b>getItem()</b>, <b>removeItem()</b> e <b>clear()</b> para interagir com os dados armazenados.</p></li>
                </ul>

                <SyntaxHighlighter language="javascript" style={darcula}>
                    {session}
                </SyntaxHighlighter>
                <br />

                
                <p>Por favor, note que o armazenamento em <b>localStorage</b> e <b>sessionStorage</b> só pode armazenar dados no formato de pares de chave-valor, e os valores devem ser strings.
                    Se você quiser armazenar objetos JavaScript, você precisará convertê-los para JSON usando <b>JSON.stringify()</b> antes de armazená-los e, em seguida,
                    converter de volta para objetos usando <b>JSON.parse()</b> quando recuperá-los.
                </p>
            </Sidebar>
        </div >
    );
}

export default JsLocalSessionStorage;



