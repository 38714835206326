import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { useNavigate } from 'react-router-dom';


import Button from '../../../Components/Button/ButtonMaterial';
import Sidebar from '../../../Components/SideBar/MiniDrawer';

function HtmlFormulariosEntradaDados() {
  const navigate = useNavigate();

  const codigo = `
    <!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Formulário HTML</title>
    <script>
        function submitForm() {
            // Capturando os valores dos campos
            var firstName = document.getElementById("fname").value;
            var lastName = document.getElementById("lname").value;
            var age = document.getElementById("age").value;

            // Exibindo os valores capturados
            alert("Nome: " + firstName + "\nSobrenome: " + lastName + "\nIdade: " + age);
        }
    </script>
</head>
<body>

<h2>Formulário de Cadastro</h2>

<form onsubmit="submitForm(); return false;">
    <div>
        <label for="fname">Nome:</label>
        <input type="text" id="fname" name="fname" placeholder="Seu nome">
    </div>
    <div>
        <label for="lname">Sobrenome:</label>
        <input type="text" id="lname" name="lname" placeholder="Seu sobrenome">
    </div>
    <div>
        <label for="age">Idade:</label>
        <input type="number" id="age" name="age" placeholder="Sua idade">
    </div>
    <div>
        <button type="submit">Enviar</button>
    </div>
</form>

</body>
</html>
  `;


  return (
    <div>
      <Sidebar>
        <h1>Formulário em Html</h1>


        <p>Utilizamos a tag <b>{'<form>'}</b>para criar o formulário.  </p>
        <p>Cada campo do formulário é definido dentro de um <b>{'<div>'}</b> para melhor organização.  </p>
        <p>Usamos <b>{'<label>'}</b> para associar cada campo do formulário com seu respectivo rótulo (label).  </p>
        <p>Os campos de entrada são definidos usando <b>{'<input>'}</b>, onde type="text" é usado para campos de texto e type="number" é usado para campos numéricos.  </p>
        <p>Atribuímos um <b>id</b> e um name único para cada campo, que pode ser útil para acessar esses campos usando JavaScript ou processar os dados do formulário no lado do servidor.  </p>
        <p>Utilizamos <b>placeholder</b> para fornecer um texto de exemplo dentro dos campos de entrada.  </p>
        <p>Por fim, temos um botão de envio <b>{'(<button type="submit">Enviar</button>)'}</b> dentro de um <b>{'<div>'}</b>, que será usado para enviar o formulário.  </p>


        <SyntaxHighlighter language="html" style={darcula}>
          {codigo}
        </SyntaxHighlighter>


      </Sidebar>
    </div>
  );
}

export default HtmlFormulariosEntradaDados;


