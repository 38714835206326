import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { useNavigate } from 'react-router-dom';


import Button from '../../../Components/Button/ButtonMaterial';
import Sidebar from '../../../Components/SideBar/MiniDrawer';

function HtmlTelaCadastro() {
    const navigate = useNavigate();

    const codigo = `
    <!DOCTYPE html>
    <html lang="en">
    <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Tela de Cadastro</title>
        <style>
            body {
                font-family: Arial, sans-serif;
                background-color: #f0f0f0;
            }
    
            .signup-container {
                width: 300px;
                margin: 100px auto;
                padding: 20px;
                background-color: #fff;
                border-radius: 5px;
                box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
            }
    
            .form-group {
                margin-bottom: 20px;
            }
    
            .form-group label {
                display: block;
                margin-bottom: 5px;
            }
    
            .form-group input {
                width: calc(100% - 16px); /* Ajuste para compensar o padding */
                padding: 8px;
                border-radius: 3px;
                border: 1px solid #ccc;
                box-sizing: border-box; /* Para incluir o padding na largura total */
            }
    
            .form-group button {
                width: 100%;
                padding: 8px;
                border: none;
                border-radius: 3px;
                background-color: #007bff;
                color: #fff;
                cursor: pointer;
            }
    
            .form-group button:hover {
                background-color: #0056b3;
            }
        </style>
    </head>
    <body>
    
    <div class="signup-container">
        <h2>Cadastro</h2>
        <div class="form-group">
            <label for="fullname">Nome Completo:</label>
            <input type="text" id="fullname" placeholder="Digite seu nome completo">
        </div>
        <div class="form-group">
            <label for="email">E-mail:</label>
            <input type="email" id="email" placeholder="Digite seu e-mail">
        </div>
        <div class="form-group">
            <label for="password">Senha:</label>
            <input type="password" id="password" placeholder="Digite sua senha">
        </div>
        <div class="form-group">
            <button onclick="signup()">Cadastrar</button>
        </div>
        <p id="message" class="error-message"></p>
    </div>
    
    <script>
        function signup() {
            var fullname = document.getElementById('fullname').value;
            var email = document.getElementById('email').value;
            var password = document.getElementById('password').value;
            var messageElement = document.getElementById('message');
    
            // Verifica se todos os campos foram preenchidos
            if (fullname && email && password) {
                // Simula um cadastro bem-sucedido
                messageElement.innerText = "Cadastro realizado com sucesso!";
                messageElement.classList.remove('error-message');
            } else {
                // Exibe uma mensagem de erro se algum campo estiver em branco
                messageElement.innerText = "Por favor, preencha todos os campos.";
                messageElement.classList.add('error-message');
            }
        }
    </script>
    
    </body>
    </html>
  `;

    return (
        <div>
            <Sidebar>
                <h1>Tela de Cadastro</h1>

                <p>
                    Este código cria uma tela de cadastro com campos para nome completo, e-mail e senha.
                    Assim como na tela de login, a validação é realizada no JavaScript quando o botão "Cadastrar" é clicado.
                    Se todos os campos estiverem preenchidos, uma mensagem de sucesso é exibida. Caso contrário,
                    uma mensagem de erro é mostrada.
                </p>

                <SyntaxHighlighter language="html" style={darcula}>
                    {codigo}
                </SyntaxHighlighter>


            </Sidebar>
        </div>
    );
}

export default HtmlTelaCadastro;


