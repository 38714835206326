import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { useNavigate } from 'react-router-dom';


import Button from '../../../Components/Button/ButtonMaterial';
import Sidebar from '../../../Components/SideBar/MiniDrawer';

function OqueeCss() {
    const navigate = useNavigate();


    return (
        <div>
            <Sidebar>
                <h1>O que é CSS</h1>

                <div>
                    CSS, que significa Cascading Style Sheets (Folhas de Estilo em Cascata), é uma linguagem de estilo usada para controlar a apresentação visual de páginas da web. Enquanto o HTML define a estrutura e o conteúdo de uma página da web, o CSS determina o layout, as cores, as fontes, o espaçamento e outros aspectos visuais.

                    Em essência, o CSS permite que os desenvolvedores web criem páginas da web com um design visualmente atraente e consistente. Com o CSS, é possível definir estilos para diferentes elementos HTML, como texto, imagens, links, listas e formulários, entre outros.

                    Os estilos CSS são aplicados aos elementos HTML usando seletores, que identificam os elementos aos quais os estilos devem ser aplicados. Por exemplo, um seletor simples como <b>p</b> é usado para selecionar todos os parágrafos em uma página, enquanto um seletor mais específico como <b>.classe</b> é usado para selecionar elementos com uma determinada classe atribuída a eles.

                    Além disso, o CSS permite a criação de regras de estilo em cascata, onde estilos definidos em um local podem ser herdados por elementos aninhados. Isso permite uma maior flexibilidade e eficiência na aplicação de estilos em uma página da web.

                    Em resumo, o CSS é uma linguagem de estilo que trabalha em conjunto com o HTML para controlar a aparência visual de páginas da web, permitindo que os desenvolvedores personalizem o layout, as cores, as fontes e outros aspectos visuais para criar uma experiência de usuário atraente e coesa.
                </div>
            </Sidebar>
        </div>
    );
}

export default OqueeCss;


